import { Box, Tooltip } from "@mui/material";
import React from "react";
import { PlayerRolls } from "./PlayerRolls";
import { ActionTracker, FearTracker } from "./TokenTrackers";

export function BottomBar() {
  return (
    <Box sx={{
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 2,
      px: 7,
      background: 'rgba(30, 30, 30, 0.8)',
      backdropFilter: 'blur(6px)',
    }}>
      <Box sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <ActionTracker/>
        <FearTracker/>
        <PlayerRolls/>
      </Box>
    </Box>
  );
}
