import { Box, Paper } from "@mui/material";
import { AdversaryImage } from "../adversaries/Adversary/AdversaryImage";
import React from "react";
import { useAdversaries } from "../adversaries/useAdversaries";
import { isEnvironment } from "../adversaries/adversary.util";

export function DevAdversaryList() {
  const { adversaries } = useAdversaries();

  // sort adversaries so that the ones without image is first
  adversaries.sort((a, b) => {
    if (a.image && !b.image) {
      return 1;
    }
    if (!a.image && b.image) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      {adversaries.map((adversary, index) => (
          <Box key={adversary.name + index} m={1} width={800}>
            <Paper elevation={3}>
              <Box display="flex">
                <Box width={200} p={1}>
                  <AdversaryImage adversary={adversary} />
                </Box>
                <Box p={2} width={400}>
                  <Box fontWeight="bold"> {adversary.name}.</Box>
                  <Box> {adversary.shortDescription}.</Box>
                  {isEnvironment(adversary) ? (
                    <Box fontStyle="italic">
                      {adversary.toneAndFeel}
                    </Box>
                  ) : (
                    <Box fontStyle="italic">
                      Holding {adversary.weapon.name}
                    </Box>
                  )}
                  <br />
                  <br />
                </Box>
              </Box>
            </Paper>
          </Box>
        )
      )}
    </>
  );
}
