import React from 'react';

const AuthButton: React.FC = () => {
  const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID!}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URI!)}&response_type=code&scope=identify`;

  return (
    <a href={discordAuthUrl}>
      <button>Login with Discord</button>
    </a>
  );
};

export default AuthButton;
