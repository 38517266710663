import { BehaviorSubject } from 'rxjs';
import { Adversary as AdversaryHbOrOfficial } from './Adversary.type';
import { useBehaviorSubject } from '../common/hooks/useSubject';
import { useListAdversariesQuery } from "../generated/graphql";
import { Environment } from "./Environment.type";

const adversarySubject = new BehaviorSubject<{
  adversaries: (AdversaryHbOrOfficial | Environment)[],
  tags: string[],
  locations: string[],
  roles: string[],
  sources: string[],
}>({
  adversaries: [],
  tags: [],
  locations: [],
  roles: [],
  sources: [],
});

const mergedFile = '/data/adversary/adversaries.json';
const mergedFileEnvironments = '/data/environment/environments.json';

export const defaultImage = '/images/adversaries/unknown.webp';

async function getDefaultAdversaries() {
  const advPromise =  fetch(mergedFile)
    .then((response) => response.json())

  const envsPromise =  fetch(mergedFileEnvironments)
    .then((response) => response.json())

  const [adversaries, environments] = await Promise.all([advPromise, envsPromise]);


  return {
    adversaries: adversaries as AdversaryHbOrOfficial[],
    environments: environments.map((env: any) => ({
      tags: ["Environments"],
      locations: [], // Only used by adversaries
      ...env,
    })) as Environment[],
  };
}

let initialized = false;

export function useAdversaries() {
  const [adversaryData, setAdversaryData] = useBehaviorSubject(adversarySubject);
  const { data } = useListAdversariesQuery();

  if (!initialized) {
    initialized = true;
    getDefaultAdversaries().then((result) => {
      const { adversaries, environments } = result;
      const all = [...adversaries, ...environments];

      // sort adversaries by tier and the name
      all.sort((a, b) => {
        if (a.tier === b.tier) {
          return a.name > b.name ? 1 : -1;
        }

        // Put adversaries without a tier at the end
        return (a.tier ?? 10) - (b.tier ?? 10);
      });

      const tags = Array.from(new Set(all.flatMap((ad) => ad.tags)))
        .sort((a, b) => (a > b ? 1 : -1))
      const locations = Array.from(new Set(all.flatMap((ad) => ad.locations)))
        .sort((a, b) => (a > b ? 1 : -1))
      const roles = Array.from(new Set(all.map((ad) => ad.type)))
        .sort((a, b) => (a > b ? 1 : -1))
        .filter((s) => s)
      const sources = Array.from(new Set(all.flatMap((ad) => ad.sources[0])))
        .sort((a, b) => (a > b ? 1 : -1))
        .filter((s) => s)

      tags.push('My Adversaries');
      // tags.push('Homebrew');

      setAdversaryData({
        adversaries: all,
        locations,
        tags,
        roles,
        sources,
      });
    });

    // getHomebrew().then((result) => {
    //   if (result.data?.listAdversaries) {
    //     const homebrew = result.data.listAdversaries
    //     // const tags = Array.from(new Set(homebrew.flatMap((ad) => ad.tags)))
    //     //   .sort((a, b) => (a > b ? 1 : -1))
    //     // const locations = Array.from(new Set(homebrew.flatMap((ad) => ad.locations)))
    //     //   .sort((a, b) => (a > b ? 1 : -1))
    //
    //     const current = adversarySubject.getValue();
    //
    //     setAdversaryData({
    //       adversaries: [...current.adversaries, ...homebrew],
    //       locations: [...current.locations],
    //       tags: [...current.tags],
    //     });
    //   }
    // });
  }

  return {
    adversaries: [
      ...adversaryData.adversaries,
      ...(data?.listAdversaries || []),
    ],
    tags: adversaryData.tags,
    locations: adversaryData.locations,
    roles: adversaryData.roles,
    sources: adversaryData.sources,
    findAdversary: (search?: PartialCard) => {
      if (!search?.name && !search?.adversaryId) {
        return undefined;
      }

      if (search.adversaryId) {
        return data?.listAdversaries
          ?.find((adversary) => adversary.id === search.adversaryId)
      }

      return adversaryData.adversaries
        .find((adversary) => adversary.name === search.name)
    },
  };
}

interface PartialCard {
  name?: string | null,
  adversaryId?: string | null,
}

export function adversaryImage(a?: { image?: string | null }) {
  return encodeURI(a?.image || defaultImage);
}
