import { D10Icon, D12Icon, D20Icon, D4Icon, D6Icon, D8Icon } from "./DiceIcons";
import { diceColor } from "./dice.util";
import { Box, Tooltip } from "@mui/material";
import { DiceRollResult } from "../generated/graphql";
import { baseGold, baseGoldDark, blue, purple, yellow } from "../core/muiTheme";
import { DiceRollPlayerName } from "./DiceRollPlayerName";

export function DiceRollResultItem(props: { result: DiceRollResult, timestamp?: number }) {
  const { isCrit, withHope, modifier, total } = props.result;

  return (
    <ResultBox result={props.result} timestamp={props.timestamp}>
      <DiceRollPlayerName playerName={props.result.playerName}/>
      <Box display="flex" flexWrap="wrap" fontSize="1em" alignContent="center" alignItems="center">
        <DiceRollResultDices result={props.result}/>
        {isCrit && (
          <Box m={0.5}>
            CRIT!
          </Box>
        )}
        {(withHope === true && !isCrit) && (
          <Box>
            with Hope
          </Box>
        )}
        {withHope === false && (
          <Box>
            with Fear
          </Box>
        )}
      </Box>
    </ResultBox>
  );
}

export function DiceRollResultDices(props: { result: DiceRollResult }) {
  const { modifier, total, diceRolls } = props.result;
  return (
    <>
      {diceRolls.map((roll, index) => (
        <Box key={index} m='0.25em' position="relative">
          <DiceBlock
            type={roll.type}
            sides={roll.sides}
            text={(roll.type === 'disadvantage' ? '-' : '') + roll.value}
          />
        </Box>
      ))}
      {modifier !== 0 && (
        <Box m='0.25em'>
          {modifier > 0 ? '+' : ''}{modifier}
        </Box>
      )}
      <Box m='0.25em'>
        <Box display="inline-block" mr='0.5em'>=</Box>{total}
      </Box>
    </>
  );
}

interface Props {
  sides: number;
  type: string | 'normal' | 'advantage' | 'disadvantage' | 'fear' | 'hope';
  size: string;
}

export function DiceIcon(props: Props) {
  const color = diceColor(props.type);

  switch (props.sides) {
    case 4:
      return <D4Icon color={color} size={props.size}/>;
    case 6:
      return <D6Icon color={color} size={props.size}/>;
    case 8:
      return <D8Icon color={color} size={props.size}/>;
    case 10:
      return <D10Icon color={color} size={props.size}/>;
    case 12:
      return <D12Icon color={color} size={props.size}/>;
    case 20:
      return <D20Icon color={color} size={props.size}/>;
  }
}

export function DiceText(props: { children: React.ReactNode }) {
  return (
    <Box sx={{
      fontWeight: 900,
      color: 'white',
      textShadow: '-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000, -1px 0 0 #000,0 -1px 0 #000,0 1px 0 #000,1px 0 0 #000',
    }}>
      {props.children}
    </Box>
  );
}

export function ResultBox(props: { result: DiceRollResult, timestamp?: number, children: React.ReactNode }) {
  const { isCrit, withHope } = props.result;

  const timeString = prettyTimeStamp(props.timestamp);

  return (
    <Tooltip title={timeString} placement="right">
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          border: '1px solid',
          padding: '0.5em',
          fontWeight: 700,
          overflow: 'hidden',
          ...resultBoxColor(props.result),
        }}
      >
        {isCrit && (
          <div className="shine"/>
        )}
        {props.children}
      </Box>
    </Tooltip>
  );
}

function prettyTimeStamp(timestampNumber: number | undefined) {
  if (!timestampNumber) {
    return '';
  }

  const timestamp = new Date(timestampNumber);

  // is the dateTime today?
  const today = new Date();
  const isToday = today.toDateString() === timestamp.toDateString();

  const time = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // Create a relevant time string
  const timeString = isToday ? time : `${timestamp.toLocaleDateString()} ${time}`;
}

function resultBoxColor(result: DiceRollResult) {
  // if (result.isCrit) {
  //   return {
  //     backgroundColor: yellow[100],
  //     borderColor: 'red',
  //   }
  // }

  if (result.withHope === true) {
    return {
      backgroundColor: baseGoldDark,
      borderColor: baseGold,
      color: 'white',
    }
  }

  if (result.withHope === false) {
    return {
      backgroundColor: blue[700],
      borderColor: blue[400],
      color: 'white',
    }
  }

  return {
    backgroundColor: purple[200],
    borderColor: purple[100],
    color: 'white',
  }

}


export function DiceBlock(props: { type: string, sides: number, text: string }) {
  const size = '1.75em';

  return (
    <Box width={size} height={size} position="relative">
      <Box position="absolute" top={0} left={0} sx={{
        '& svg': {
          display: 'block',
        }
      }}>
        <DiceIcon sides={props.sides} type={props.type} size={size}/>
      </Box>
      <Box width={size} height={size} position="relative" display="flex" alignItems="center" justifyContent="center">
        <DiceText>
          {props.text}
        </DiceText>
      </Box>
    </Box>
  );
}

export function DualityDiceBlock() {
  const size = '1.75em';

  return (
    <Box width={1.75 * 2 + 'em'} height={size} position="relative">
      <Box position="absolute" top={0} left={0} sx={{
        '& svg': {
          display: 'block',
        },
        display: 'flex',
      }}>
        <DiceIcon sides={12} type="hope" size={size}/>
        <DiceIcon sides={12} type="fear" size={size}/>
      </Box>
      <Box width={1.75 * 2 + 'em'} height={size} position="relative" display="flex" alignItems="center"
           justifyContent="center">
        <DiceText>
          Duality
        </DiceText>
      </Box>
    </Box>
  );
}
