import Dialog from "@mui/material/Dialog";
import { useCreateAdversaryMutation } from "../../generated/graphql";
import { HbAdversaryForm } from "./HbAdversaryForm";
import React from "react";
import ErrorMessage from "../../common/ErrorMessage";
import { defaultAdversaryFormData } from "./config/defaultAdversaryFormData";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ModalInner } from "../../common/ModalInner";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function HbAdversaryCreateDialog(props: Props) {
  // Will close on escape key, but not when clicking outside the dialog
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <ModalInner>
        <Box onClick={(e) => e.stopPropagation()}>
          <AdversaryCreateController onClose={props.handleClose} />
        </Box>
      </ModalInner>
    </Modal>
  );
}

function AdversaryCreateController(props: { onClose: () => void }) {
  const [call, { error, loading }] = useCreateAdversaryMutation({
    refetchQueries: [
      'ListAdversaries',
      'ListMyAdversaries',
    ],
  });

  return (
    <>
      <ErrorMessage error={error} />
      <HbAdversaryForm
        isNew
        loading={loading}
        onClose={props.onClose}
        onSubmit={(adversary) => {
          call({
            variables: {
              input: adversary,
            }
          }).then(() => {
            props.onClose();
          });
        }}
        defaultData={defaultAdversaryFormData}
      />
    </>
  );
}


