import { BehaviorSubject } from "rxjs";
import { useBehaviorSubject } from "../../common/hooks/useSubject";

export type SelectedDie = {
  sides: number;
  type: string;
};

const selectedDieSubject = new BehaviorSubject<SelectedDie[]>([]);

export function useSelectedDie() {
  return useBehaviorSubject(selectedDieSubject);
}

