import { Box } from '@mui/material';
import React from 'react';
import { useSettings } from "../hooks/useSettings";
import { baseDarkBlue, basePurple } from "../../core/muiTheme";

export function EncounterBackground(props: {
  background?: string,
  opacity?: number,
}) {
  const {settings} = useSettings();

  if (settings.background === 'solid') {
    return <Box sx={{
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      zIndex: -1,
      background: `radial-gradient(circle, ${basePurple} 0%, ${baseDarkBlue} 100%)`,
    }}/>
  }

  if (settings.background === 'greenScreen') {
    return <Box sx={{
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      zIndex: -1,
      backgroundColor: '#03ff00',
    }}/>
  }

  return (
    <>
      <Box sx={{
        position: 'fixed',
        right: -6,
        bottom: -6,
        top: -6,
        left: -6,
        zIndex: -1,
        backgroundColor: props.background || '#333',
      }}/>
      <Box sx={{
        backgroundImage: 'url(/images/bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'fixed',
        right: -6,
        bottom: -6,
        top: -6,
        left: -6,
        zIndex: -1,
        filter: 'blur(3px)',
        opacity: props.opacity || 0.2,
      }}/>
    </>
  );
}
