import { useState, useEffect } from "react";
import { DiceRollResultInput, useRegisterDiceRollMutation } from "../../generated/graphql";
import { usePlayerGameId } from "./usePlayerGameId";
import { getClientId } from "../../core/ablyClient";

const RETRY_DELAY = 5000; // 5 seconds
const MAX_RETRIES = 3; // Max number of retries

export function useSendDiceRoll() {
  const [registerDiceRoll, { loading, error }] = useRegisterDiceRollMutation();
  const { gameId } = usePlayerGameId();
  const [retryCountdown, setRetryCountdown] = useState<number | null>(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [lastDiceRollResultInput, setLastDiceRollResultInput] = useState<DiceRollResultInput | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (retryCountdown !== null && retryCountdown > 0) {
      timer = setTimeout(() => setRetryCountdown(retryCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [retryCountdown]);

  const attemptRegisterDiceRoll = (diceRollResultInput: DiceRollResultInput) => {
    if (!gameId) {
      return null;
    }

    registerDiceRoll({
      variables: {
        sessionId: gameId,
        diceRollEvent: {
          playerId: getClientId().toString(),
          result: diceRollResultInput,
        }
      }
    }).then().catch((err) => {
      console.error("Error registering dice roll:", err);
      if (retryAttempt < MAX_RETRIES) {
        setRetryAttempt(retryAttempt + 1);
        setRetryCountdown(RETRY_DELAY / 1000);
      }
    });
  };

  useEffect(() => {
    if (retryCountdown === 0 && lastDiceRollResultInput !== null) {
      setRetryCountdown(null);
      attemptRegisterDiceRoll(lastDiceRollResultInput);
    }
  }, [retryCountdown, lastDiceRollResultInput]);

  const sendDiceRoll = (diceRollResultInput: DiceRollResultInput) => {
    if (!gameId) {
      return null;
    }

    setRetryAttempt(0);
    setLastDiceRollResultInput(diceRollResultInput);
    attemptRegisterDiceRoll(diceRollResultInput);
  };

  return { sendDiceRoll, loading, error, retryCountdown };
}
