import { Box } from '@mui/material';
import { baseGold, baseGoldDark, baseGoldLight } from "../../core/muiTheme";

export function TierBadge(props: {
  tier?: number | string | null
  borderColor?: string
}) {
  return (
    <Box sx={{
      clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
      backgroundColor: props.borderColor || 'rgba(0,0,0,0.25)',
      color: 'warning.contrastText',
      // borderRadius: '100%',
      // boxShadow: '0 0 1em 0 rgba(0,0,0,0.3)',
      fontWeight: '900',
      fontSize: '1.5em',
      // textShadow: '1px 1px 1px rgba(0,0,0,0.2)',
      padding: '2px',
    }}>
      <Box sx={{
        width: '1.5em',
        height: '1.5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `radial-gradient(${baseGoldLight}, ${baseGoldDark})`,
        pt: '0.1em',
        clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
      }}>
        {props.tier ?? 'X'}
      </Box>
    </Box>
  );
}
