import { Control, useFieldArray } from "react-hook-form";
import { AdversaryInput, FeatureType } from "../../generated/graphql";
import { Box, Button, Paper } from "@mui/material";
import { FormInputNumber, FormInputSelect, FormInputText } from "./HbAdversaryFormInputs";
import { featureTypeEnum } from "./config/enums";
import { HbAdversaryFormFeatureMenu } from "./HbAdversaryFormFeatureMenu";
import React from "react";
import { AdversaryFormData } from "./HbAdversaryFormData.types";
import AddIcon from "@mui/icons-material/Add";
import { getObjectId } from "../../common/util/objectId";

interface Props {
  control: Control<AdversaryFormData>;
  watch: (name: keyof AdversaryFormData) => any;
}

export function HbAdversaryFeatureForm(props: Props) {
  const selectedFeatures = props.watch('features');

  const {
    fields: featureFields,
    append: appendFeature,
    remove: removeFeature,
    move: moveFeature,
  } = useFieldArray({
    control: props.control,
    name: 'features',
  });
  return (
    <>
    {featureFields.map((feature, index) => (
        <Box key={getObjectId(feature)} mb={2}>
          <FeatureForm
            index={index}
            control={props.control}
            selectedFeatures={selectedFeatures}
            remove={() => removeFeature(index)}
            moveUp={() => moveFeature(index, index - 1)}
            moveDown={() => moveFeature(index, index + 1)}
            canMoveUp={index > 0}
            canMoveDown={index < featureFields.length - 1}
          />
        </Box>
      ))}
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => appendFeature({ name: '', type: FeatureType.Action, description: '', summon: [] })}
          startIcon={<AddIcon />}
        >
          Add Feature
        </Button>
      </Box>
    </>
  )
}


interface FeatureFormProps {
  index: number;
  remove: () => void;
  moveUp: () => void;
  moveDown: () => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  control: Control<AdversaryFormData>;
  selectedFeatures: AdversaryInput['features'];
}

function FeatureForm(props: FeatureFormProps) {
  const { index: i, control } = props;

  return (
    <Paper sx={{ p: 2, pb: 1 }} variant="outlined">
      <Row>
        <Box flexGrow={1}>
          <FormInputText name={`features.${i}.name`} control={control} label="Feature Name" />
        </Box>
        <Box flexGrow={1}>
          <FormInputSelect name={`features.${i}.type`} control={control} label="Feature Type"
                           options={featureTypeEnum} />
        </Box>
        <HbAdversaryFormFeatureMenu
          onDelete={props.remove}
          onMoveUp={props.moveUp}
          onMoveDown={props.moveDown}
          canMoveUp={props.canMoveUp}
          canMoveDown={props.canMoveDown}
        />
      </Row>
      <Row>

        {props.selectedFeatures[i]?.type === FeatureType.Passive && (
          <Box flexGrow={1}>
            <FormInputNumber name={`features.${i}.value`} control={control} label="Passive Value" valueAsNumber
                             helperText="Values shown after feature type. Used by Relentless, Slow and Minion" />
          </Box>
        )}
        <FormInputText name={`features.${i}.description`} control={control} label="Feature Description" multiline
                       minRows={2} />
      </Row>
      <Row>
        <FormInputText name={`features.${i}.flavourText`} control={control} label="Flavour Text" />
      </Row>
      <Row>
        <FormInputNumber name={`features.${i}.uses`} control={control} label="Max Uses per scene" valueAsNumber />
      </Row>
      <Row>
        {props.selectedFeatures[i]?.type === FeatureType.Action && (
          <Box flex={1}>
            <FormInputNumber name={`features.${i}.cost.actions`} control={control} label="Action Cost" valueAsNumber />
          </Box>
        )}
        <Box flex={1}>
          <FormInputNumber name={`features.${i}.cost.fear`} control={control} label="Fear Cost" valueAsNumber />
        </Box>
        <Box flex={1}>
          <FormInputNumber name={`features.${i}.cost.stress`} control={control} label="Stress Cost" valueAsNumber />
        </Box>
        <Box flex={1}>
          <FormInputNumber name={`features.${i}.cost.hitPoints`} control={control} label="HP Cost"
                           valueAsNumber />
        </Box>
      </Row>
    </Paper>
  );
}

function Row(props: { children: React.ReactNode }) {
  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      flexWrap: 'wrap',
      mb: 3,
    }}>{props.children}</Box>
  );
}
