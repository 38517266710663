import { Box } from '@mui/material';
import {
  baseBlue,
  baseGoldDark, baseGreen,
  basePurple,
  basePurpleLight, baseTeal, blue,
  grey,
  theme
} from '../../core/muiTheme';

interface Props {
  type?: string;
  isEnv: boolean;
}

const borderHeight = 6;
const cellHeight = 25;
const padding = 3;

const colorM = theme.palette.warning.main;
const colorL = theme.palette.warning.light;
const colorD = theme.palette.warning.dark;

export function AdversaryTypeWithDivider(props: Props) {
  return (
    <Box sx={{
      height: `${borderHeight}px`,
      backgroundColor: 'warning.main',
      background: `linear-gradient(90deg, ${colorM} 0%, ${colorL} 50%, ${colorM} 100%)`,
      textAlign: 'center',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // top: `${-cellHeight / 2 + borderHeight / 2 - padding / 2}px`,
        transform: 'translateY(-50%)',
        top: `${borderHeight / 2}px`,
        position: 'relative',
      }}>
        {props.isEnv ? (
          <AdversaryTypeEnv type={props.type} />
        ) : (
          <AdversaryType type={props.type} />
        )}
      </Box>
    </Box>
  );
}

export function AdversaryType(props: { type?: string }) {
  if (!props.type) return null;

  // https://codepen.io/GeorgePark/pen/ZoaRrV

  return (
    <Box sx={{
      background: `linear-gradient(90deg, ${colorM} 0%, ${colorL} 50%, ${colorM} 100%)`,
      padding: `${padding}px ${padding}px`,
      // borderRadius: `${padding + 6}px`,
      // boxShadow: '0 0 15px rgba(0,0,0,0.2)',
      clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
    }}>
      <Box sx={{
        // inset shadow
        // boxShadow: 'inset 0 0 10px rgba(0,0,0,0.2)',
        // background: typeColor(props.type),
        background: `radial-gradient(circle, ${typeColor(props.type)} 0%, #000 300%)`,
        // lineHeight: `${cellHeight - padding}px`,
        position: 'relative',
        padding: '0.1em 2.2em',
        textTransform: 'uppercase',
        fontWeight: '900',
        // borderRadius: '6px',
        fontSize: '0.9em',
        color: 'white',
        clipPath: 'polygon(25% 0%, 75% 0%, 95% 50%, 75% 100%, 25% 100%, 5% 50%)',
      }}>
        {props.type}
      </Box>
    </Box>
  );
}


export function AdversaryTypeEnv(props: { type?: string }) {
  if (!props.type) return null;

  // https://codepen.io/GeorgePark/pen/ZoaRrV

  return (
    <Box sx={{
      background: `linear-gradient(90deg, ${colorM} 0%, ${colorL} 50%, ${colorM} 100%)`,
      padding: `${padding}px ${padding}px`,
      // borderRadius: `${padding + 6}px`,
      // boxShadow: '0 0 15px rgba(0,0,0,0.2)',
      clipPath: 'polygon(10% 0%, 90% 0%, 100% 40%, 100% 60%, 90% 100%, 10% 100%, 0% 60%, 0% 40%)',
    }}>
      <Box sx={{
        // inset shadow
        // boxShadow: 'inset 0 0 10px rgba(0,0,0,0.2)',
        // background: typeColor(props.type),
        background: `radial-gradient(circle, ${typeColor(props.type)} 0%, #000 300%)`,
        // lineHeight: `${cellHeight - padding}px`,
        position: 'relative',
        padding: '0.1em 1.8em',
        textTransform: 'uppercase',
        fontWeight: '900',
        // borderRadius: '6px',
        fontSize: '0.9em',
        color: 'white',
        clipPath: 'polygon(10% 0%, 90% 0%, 98% 38%, 98% 62%, 90% 100%, 10% 100%, 2% 62%, 2% 38%)',
      }}>
        {props.type}
      </Box>
    </Box>
  );
}

export function AdversaryTypeSimple(props: { type?: string }) {
  if (!props.type) return null;

  const borderWidth = 2;
  const borderRadius = 4;

  return (
    <Box sx={{
      background: `linear-gradient(90deg, ${colorM} 0%, ${colorL} 50%, ${colorM} 100%)`,
      padding: `${borderWidth}px ${borderWidth}px`,
      borderRadius: `${borderWidth + borderRadius}px`,
      boxShadow: '0 0 15px rgba(0,0,0,0.2)',
    }}>
      <Box sx={{
        // inset shadow
        boxShadow: 'inset 0 0 10px rgba(0,0,0,0.2)',
        background: typeColor(props.type),
        lineHeight: '1.3em',
        position: 'relative',
        padding: '0.5px 0.5em',
        textTransform: 'uppercase',
        fontWeight: '900',
        borderRadius: `${borderRadius}px`,
        color: 'white',
      }}>
        {props.type}
      </Box>
    </Box>
  );
}


function typeColor(type: string) {
  switch (type) {
    case 'Standard':
      return baseBlue;
    case 'Ranged':
      return blue[300];
    case 'Leader':
      return baseGoldDark;
    case 'Skulk':
      return basePurpleLight;
    case 'Bruiser':
      return blue[800];
    case 'Horde':
      return basePurple;
    case 'Minion':
      return grey[600];
    case 'Solo':
      return theme.palette.error.dark;
    case 'Support':
      return blue[200];
    case 'Social':
      return baseTeal;
    case 'Exploration':
    case 'Traversal':
      return baseGreen;
    default:
      return theme.palette.primary.dark;
  }
}
