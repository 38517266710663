import { AdversaryInput } from "../../generated/graphql";
import { AdversaryFormData } from "./HbAdversaryFormData.types";


export function formDataToInput(data: AdversaryFormData): AdversaryInput {
  return {
    ...data,
    experience: data.experience
      .split(',')
      .map((x) => x.trim())
      .filter((v) => v),
    motivesAndTactics: data.motivesAndTactics
      .split(',')
      .map((x) => x.trim())
      .filter((v) => v),
    features: data.features.map((feature) => ({
      ...feature,
      cost: {
        actions: feature.cost?.actions || undefined,
        fear: feature.cost?.fear || undefined,
        stress: feature.cost?.stress || undefined,
        hitPoints: feature.cost?.hitPoints || undefined,
      },
      uses: feature.uses || undefined,
      summon: feature.summon || [],
    })),
  }
}
