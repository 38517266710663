import { Box } from "@mui/material";
import React from "react";

export function DottedDivider() {
  return (
    <Box sx={{
      height: 0,
      borderTop: '3px dotted #ccc',
      borderColor: 'warning.main',
      margin: '0 20px 10px',
    }}>
    </Box>
  );
}
