import { Box, ButtonGroup } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useDeck } from './DeckBuilder/useDeck';
import { useAdversaries } from '../../adversaries/useAdversaries';
import { AdversaryCard } from '../../adversaries/AdversaryCard';
import { useSelectedCard } from '../sidebar/useSidebar';
import { CardEditDialog } from './CardCustomization/CardEditDialog';
import { adversaryMainName } from "../../adversaries/Adversary/AdversaryName";
import { useAdversaryDiceRoll } from "../../adversaries/hooks/useAdversaryDiceRoll";

export function AdversarySidebar() {
  const { selectedCard, setSelectedCard } = useSelectedCard();
  const { deck, updateCardInDeck, removeCardFromDeck, addDiceResultToCard } = useDeck();
  const { findAdversary } = useAdversaries();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const card = deck.find((card) => card.id === selectedCard);
  const adversary = findAdversary(card);

  const { rollDice } = useAdversaryDiceRoll();

  if (!card) {
    return null;
  }

  const onDiceRoll = async (dice: string) => {
    const name = adversaryMainName(adversary?.name || '', card?.instances ?? [])
    const result = await rollDice(dice, name);

    if (result) {
      addDiceResultToCard(card.id, result);
    }
  }

  return (
    <Box sx={{
      width: '24em',
      background: '#fff',
      boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.9), 0 0 15px 0 rgba(0, 0, 0, 0.4)',
      height: '100%',
      position: 'relative',
      // borderLeft: `2px solid ${yellow[500]}`,
      zIndex: 3,
      overflowY: 'auto',
      '& > .MuiCard-root': {
        borderRadius: 0,
      },
    }}>
      <ButtonGroup
        variant="contained"
        fullWidth
        sx={{
          '& .MuiButton-root': {
            borderRadius: 0,
          },
        }}
      >
        <Button color="error" onClick={() => removeCardFromDeck(card.id)} startIcon={<DeleteIcon/>}>Delete</Button>
        <Button color="primary" onClick={() => setOpenEditDialog(true)} startIcon={<EditIcon/>}>Edit</Button>
        <Button color="warning" onClick={() => setSelectedCard(null)} startIcon={<CloseIcon/>}>Close</Button>
      </ButtonGroup>
      <AdversaryCard
        key={card.id} // Makes sure animations reset when card changes
        card={card}
        adversary={adversary}
        elevation={0}
        onInstancesUpdate={(instances) => {
          updateCardInDeck(card.id, {
            ...card,
            quantity: instances.length,
            instances,
          });
        }}
        onDiceRoll={onDiceRoll}
      />
      {(openEditDialog && adversary) && (
        <CardEditDialog
          open={true}
          onClose={() => setOpenEditDialog(false)}
          card={card}
          adversary={adversary}
        />
      )}
    </Box>
  );
}
