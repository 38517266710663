import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export function TopLeft(props: PropsWithChildren) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0.7em',
        left: '0.7em',
        zIndex: 1,
        display: 'flex',
      }}
    >
      {props.children}
    </Box>
  );
}
