import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useAdversaries } from '../../../adversaries/useAdversaries';
import { DeckCatalog } from './DeckCatalog/DeckCatalog';
import { DeckList } from './DeckList';
import { Header2 } from '../../../common/typography/Header2';
import { EncounterNameInput } from "./EncounterNameInput";
import FilterComponent, { filterDefault } from "./DeckFilters";
import { adversarySearchScore } from "./filter.util";
import { DottedDivider } from "../../../common/DottedDivider";

export function EncounterBuilderDialog(props: { close: () => void }) {
  const {
    adversaries,
    tags,
    locations ,
    sources,
    roles,
  } = useAdversaries();
  const [filter, setFilter] = useState(filterDefault);

  const filteredAdversaries = adversaries
    .map((ad) => {
      return {
        score: adversarySearchScore(ad, filter.search.toLowerCase()),
        adversary: ad,
      };
    })
    .filter((a) => a.score > 0)
    .sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      } else if (b.score > a.score) {
        return 1;
      }
      return 0;
    })
    .map((a) => a.adversary)
    .filter((ad) => {
      return filter.category === '' || ad.tags.includes(filter.category);
    })
    .filter((ad) => {
      return filter.biome === '' || ad.locations.includes(filter.biome);
    })
    .filter((ad) => {
      return filter.tiers.length === 0 || ad.tier === undefined || ad.tier === null || filter.tiers.includes(ad.tier);
    })
    .filter((ad) => {
      return filter.source === '' || ad.sources.includes(filter.source);
    })
    .filter((ad) => {
      return filter.role === '' || ad.type === filter.role;
    });

  return (
    <Box sx={{
      height: '90vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch',
      overflow: 'hidden',
      minWidth: 820,
      overflowX: 'auto',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 5,
      }}>
        <Box sx={{
          display: 'flex',
          px: 2,
          alignItems: 'center',
        }}>
          <Header2 sx={{ textAlign: 'center', pr: 2 }}>Select Adversaries</Header2>
          <Box sx={{ flexGrow: 1, py: 1 }}>
            <FilterComponent
              categories={tags}
              biomes={locations}
              sources={sources}
              roles={roles}
              onFilterChange={(f) => {
                setFilter(f);
              }}
            />
          </Box>
        </Box>
        <DottedDivider/>
        <DeckCatalog adversaries={filteredAdversaries}/>
      </Box>
      <Box sx={{
        borderLeft: '3px solid #ccc',
        borderColor: 'warning.main',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 100,
        maxWidth: 300,
        flexGrow: 1,
      }}>
        <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
          <Header2 sx={{
            px: 1,
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>Encounter Deck</Header2>
          <DottedDivider/>
          <Box sx={{ pr: 1, pl: 2, pt: 1, }}>
            <DeckList/>
          </Box>
        </Box>
        <DialogActions>
          <EncounterNameInput/>
          <Button
            onClick={props.close}
            variant="contained"
          >Close</Button>
        </DialogActions>
      </Box>
    </Box>
  );
}
