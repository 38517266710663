import { Box } from '@mui/material';

export function UpscaleBadge(props: { upscale?: number }) {
  if (!props.upscale) {
    return null;
  }

  return (
    <Box sx={{
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '900',
      fontSize: '1.5em',
      textShadow: '-2px -2px 0 #000,2px -2px 0 #000,-2px 2px 0 #000,2px 2px 0 #000, -2px 0 0 #000,0 -2px 0 #000,0 2px 0 #000,2px 0 0 #000',
      margin: '0 0.25em',
    }}>
      {props.upscale > 0 ? '+' : ''}
      {props.upscale}
    </Box>
  );
}
