import React from 'react';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { DiceRollResultDices, DiceText } from "../dice/DiceRollResultItem";
import { DiceRollResultInput } from "../generated/graphql";
import { grey, purple } from "../core/muiTheme";

interface DiceRollListProps {
  rolls: DiceRollResultInput[];
}

export const AdversaryDiceRolls: React.FC<DiceRollListProps> = ({ rolls }) => {
  // Keep only the last 3 items
  const lastThreeRolls = rolls
    .map((roll, i) => ({ ...roll, id: i }))
    .slice(-3);

  return (
    <Box sx={{
      position: 'absolute',
      bottom: '1em',
      right: '1em',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      maxWidth: '80%',
    }}>
      <AnimatePresence initial={false}>
        {lastThreeRolls.map((roll, index) => (
          <motion.div
            key={roll.id}
            initial={{ opacity: 1, maxHeight: 0 }}
            animate={{ opacity: 1, maxHeight: 200 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mb: '0.5em',
                background: grey[700] + '88',
                borderRadius: '8px',
                padding: '0.1em 0.5em',
                backdropFilter: 'blur(3px)',
                fontWeight: 900,
                color: 'white',
                flexWrap: 'wrap',
              }}>
                <DiceRollResultDices result={roll}/>
              </Box>
          </motion.div>
        ))}
      </AnimatePresence>
    </Box>
  );
};
