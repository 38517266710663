import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { CardInstance } from "../../game-master/encounter/DeckBuilder/Deck.types";

interface Props {
  name: string
  instances?: CardInstance[]
}

export function AdversaryName(props: Props) {
  const { instances, name } = props

  return (
    <Box sx={{
      fontSize: '1.4em',
      lineHeight: 1.2,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      mb: 1,
    }}>
      {instances ? adversaryMainName(name, instances) : name}
    </Box>
  );
}


export function adversaryMainName(name: string, instances: CardInstance[]) {
  return (instances?.length === 1 && instances[0].nameOverride) ? instances[0].nameOverride : name
}

export function adversaryInstanceName(name: string, instance: CardInstance, index: number) {
  return (instance.nameOverride) ? instance.nameOverride : `${name} #${index + 1}`
}

export function adversaryInstancePlaceholder(name: string, instances: CardInstance[], index: number) {
  return (instances?.length === 1) ? name : `${name} #${index + 1}`
}
