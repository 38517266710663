import { useListMyAdversariesQuery } from "../../generated/graphql";
import { Box } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage";
import React from "react";
import { DeckCatalogGrid } from "../encounter/DeckBuilder/DeckCatalog/DeckCatalogGrid";
import { HbAdversaryCreateButton } from "./HbAdversaryCreateButton";
import { HbAdversaryGridItem } from "./HbAdversaryGridItem";

export function HomebrewCatalog() {
  const { data, error } = useListMyAdversariesQuery();

  return (
    <Box pt={1}>
      <ErrorMessage error={error} />
      <DeckCatalogGrid>
        <HbAdversaryCreateButton />
        {data?.listMyAdversaries?.map((adversary) => (
          <Box key={adversary.name + adversary.id}>
            <HbAdversaryGridItem
              adversary={adversary}
            />
          </Box>
        ))}
      </DeckCatalogGrid>
    </Box>
  );
}



