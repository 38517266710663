import { useSettings } from "../hooks/useSettings";
import { useLocalStorage } from "usehooks-ts";
import { Box, Button, Tooltip } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { baseBlue, baseGoldDark, basePurpleLight, blue, grey, purple } from "../../core/muiTheme";
import AddIcon from "@mui/icons-material/Add";
import React from "react";


export function ActionTracker() {
  const { settings } = useSettings();
  const [actions, setAction] = useLocalStorage('actionTokens', 0)

  if (!settings.showActionTracker) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      fontSize: 26,
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.2em',
      height: '70px',
    }}>
      <IncrementButton
        onClick={() => setAction(actions - 1)}
        left={true}
      ><RemoveIcon/></IncrementButton>
      <Tooltip title="Action tokens" placement="top">
        <Box sx={{
          border: '4px dashed',
          borderColor: 'rgba(0, 0, 0, 0.4)',
          background: baseGoldDark,
          borderRadius: '999px',
          width: '1.9em',
          height: '1.9em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.7)',
          fontWeight: 700,
          boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)',
          position: 'relative',
          zIndex: 2,
        }}>{actions}</Box>
      </Tooltip>
      <IncrementButton
        onClick={() => setAction(actions + 1)}
        left={false}
      ><AddIcon/></IncrementButton>
    </Box>
  );
}


export function FearTracker() {
  const { settings } = useSettings();
  const [actions, setFear] = useLocalStorage('fearTokens', 0)

  if (!settings.showFearTracker) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      fontSize: 26,
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.2em',
      height: '70px',
    }}>
      <IncrementButton
        onClick={() => setFear(actions - 1)}
        left={true}
      ><RemoveIcon/></IncrementButton>
      <Tooltip title="Fear tokens" placement="top">
        <Box sx={{
          border: '4px dashed',
          borderColor: blue[500],
          background: blue[800],
          borderRadius: '999px',
          width: '1.9em',
          height: '1.9em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'rgba(255, 255, 255, 0.8)',
          fontWeight: 700,
          boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)',
          position: 'relative',
          zIndex: 2,
        }}>{actions}</Box>
      </Tooltip>
      <IncrementButton
        onClick={() => setFear(actions + 1)}
        left={false}
      ><AddIcon/></IncrementButton>
    </Box>
  );
}


interface IncrementButtonProps {
  onClick: () => void;
  left: boolean;
  children: React.ReactNode;
}

export function IncrementButton(props: IncrementButtonProps) {
  const { onClick, children, left } = props;
  return (
    <Button
      color="inherit"
      variant="outlined"
      sx={{
        pl: left ? 0 : 3,
        pr: left ? 3 : 0,
        mr: left ? '-35px' : 0,
        ml: left ? 0 : '-35px',
        height: '40px',
        width: '70px',
        borderRadius: 2,
        color: grey[400],
        borderColor: grey[700],
        // background: grey[200],
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.3)',
        },
      }}
      onClick={onClick}
    >{children}</Button>
  );
}
