import React from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import { useSelectedDie } from "./hooks/useDiceSelection";
import { DiceBlock, DualityDiceBlock } from "./DiceRollResultItem";
import { ModifierInput } from "./ModifierInput";
import { DiceRollButton } from "./DiceRollButton";
import { purple } from "../core/muiTheme";
import { useSendDiceRoll } from "../player/hooks/useSendDiceRoll";
import ErrorMessage from "../common/ErrorMessage";

function DicePicker() {
  const [dice, setDice] = useSelectedDie();
  const { sendDiceRoll, error, retryCountdown } = useSendDiceRoll();

  const addDie = (sides: number, type: string = 'normal') => {
    setDice([...dice, { sides, type }]);
  };

  const handleDuality = () => {
    setDice([
      ...dice,
      { sides: 12, type: 'hope' },
      { sides: 12, type: 'fear' },
    ]);
  };

  const hasHope = dice.some(die => die.type === 'hope');

  return (
    <Box>
      <ErrorMessage error={error} />
      {retryCountdown && (
        <Box textAlign="center" color="white">
          Retrying in {retryCountdown} seconds
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" sx={{
        '& button': {
          flexGrow: 1,
          minWidth: '0',
          fontSize: '1.1rem',
        }
      }}>
        <Button onClick={() => addDie(4)}>
          <DiceBlock sides={4} text={'d4'} type="normal"/>
        </Button>
        <Button onClick={() => addDie(6)}>
          <DiceBlock sides={6} text={'d6'} type="normal"/>
        </Button>
        <Button onClick={() => addDie(8)}>
          <DiceBlock sides={8} text={'d8'} type="normal"/>
        </Button>
        <Button onClick={() => addDie(10)}>
          <DiceBlock sides={10} text={'d10'} type="normal"/>
        </Button>
        <Button onClick={() => addDie(12)}>
          <DiceBlock sides={12} text={'d12'} type="normal"/>
        </Button>
        <Button onClick={() => addDie(20)}>
          <DiceBlock sides={20} text={'d20'} type="normal"/>
        </Button>
      </Box>
      <Box display="flex" flexWrap="wrap" mt={1} sx={{
        alignItems: 'center',
        '& button': {
          fontSize: '1.1rem',
        }
      }}>
        <Box width="33.33%" display="flex" sx={{ '& > *': { flexGrow: 1 } }}>
          {!hasHope && (
            <Button onClick={handleDuality}>
              <DualityDiceBlock/>
            </Button>
          )}
          {hasHope && (
            <>
              <Button onClick={() => addDie(6, 'advantage')}>
                <DiceBlock sides={6} text={'ADV'} type="advantage"/>
              </Button>
              <Button onClick={() => addDie(6, 'disadvantage')}>
                <DiceBlock sides={6} text={'DISADV'} type="disadvantage"/>
              </Button>
            </>
          )}
        </Box>
        <Box flexGrow={1}/>
        <Box sx={{
          '& button, & .MuiInputBase-root': {
            borderColor: purple[900],
            background: purple[500],
            fontWeight: 'bold',
            color: '#fff',
          },
          '& button:hover': {
            background: purple[800],
          }
        }}>
          <ModifierInput/>
        </Box>
        <Box ml={1}>
          <DiceRollButton sendDiceRoll={sendDiceRoll}/>
        </Box>
      </Box>
    </Box>
  );
};

export default DicePicker;
