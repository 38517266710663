import { Box } from '@mui/material';
import { Environment } from '../Environment.type';

export function AdversaryEnvironmentToneAndFeel(props: { adversary: Environment }) {
  const { toneAndFeel } = props.adversary;

  if (!toneAndFeel || toneAndFeel.length === 0) {
    return null;
  }

  return (
    <Box sx={{
      mt: '0.5em',
    }}>
      <strong>Tone &amp; feel:</strong>
      <Box sx={{
        fontStyle: 'italic',
      }}>
        {toneAndFeel}
      </Box>
    </Box>
  );
}
