import React from 'react';
import { Box } from '@mui/material';
import { Adversary } from '../../../../adversaries/Adversary.type';
import { AdversaryCardAdd } from './AdversaryCardAdd';
import { DeckCatalogGrid } from "./DeckCatalogGrid";
import { Environment } from "../../../../adversaries/Environment.type";

interface DeckBuilderProps {
  adversaries: (Adversary | Environment)[];
}

export function DeckCatalog(props: DeckBuilderProps) {
  const { adversaries } = props;

  if (adversaries.length === 0) {
    return <Box p={2} textAlign="center">No adversaries found</Box>;
  }

  return (
    <DeckCatalogGrid>
      {adversaries.map((adversary) => (
        <Box key={adversary.name + adversary.id}>
          <AdversaryCardAdd
            adversary={adversary}
          />
        </Box>
      ))}
    </DeckCatalogGrid>
  );
}
