import { Box, CardActionArea } from "@mui/material";
import { DiceRollHistory, LastDiceRoll } from "../../player/DiceRollHistory";
import { useSettings } from "../hooks/useSettings";
import { useGmGameId } from "../hooks/useGmGameId";
import { initiateDiceRollListener, useDiceRollHistory } from "../../dice/hooks/useDiceRollHistory";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { purple } from "../../core/muiTheme";
import { PlayerInviteMessage } from "../top-bar/menu-items/PlayersButton";

export function PlayerRolls() {
  const { settings } = useSettings();
  const gameId = useGmGameId();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [diceRollEvents] = useDiceRollHistory();


  if (!settings.showPlayerDiceRolls) {
    return null;
  }

  setTimeout(() => initiateDiceRollListener(gameId), 200);

  return (
    <Box sx={{
      width: '420px',
      height: '70px',
      overflowY: 'auto',
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        flexGrow: 1,
      },
      '& > * > *': {
        minHeight: '40px',
        py: '2px'
      },
    }}>
      <CardActionArea onClick={() => setDialogOpen(true)}>
        <LastDiceRoll/>
      </CardActionArea>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth maxWidth="sm"
        PaperProps={{
          sx: {
            pt: 1,
            px: 0.75,
            pb: 0,
            backgroundColor: purple[300],
            borderRadius: 2,
            borderWidth: 2,
            borderColor: purple[400],
            borderStyle: 'solid',
          },
        }}>
        <Box>
          {(diceRollEvents.length === 0) && (
            <Box sx={{
              color: 'white',
              px: 2,
              pb: 2,
              '& .MuiInputBase-root': {
                background: 'white',
              }
            }}>
              <PlayerInviteMessage />
            </Box>
          )}
          <DiceRollHistory/>
        </Box>
      </Dialog>
    </Box>
  );
}
