import { forwardRef, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

/**
 * A modal inner component that centers its content and allows for scrolling
 */
export const ModalInner = forwardRef((props: PropsWithChildren, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box sx={{
        overflowY: 'auto',
        height: '100%',
      }}>
        {props.children}
      </Box>
    </Box>
  );
});
