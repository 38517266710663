import React from 'react';
import { CardCustomizationDialog } from './CardCustomizationDialog';
import { DeckCardUpdateProps } from '../DeckBuilder/Deck.types';
import { Adversary } from '../../../adversaries/Adversary.type';
import { useDeck } from '../DeckBuilder/useDeck';
import { Environment } from "../../../adversaries/Environment.type";

interface Props {
  adversary: Adversary | Environment;
  open: boolean;
  onClose: () => void;
}

export function CardAddDialog(props: Props) {
  const { addCardToDeck } = useDeck();

  const handleSaveCustomization = (c: DeckCardUpdateProps) => {
    props.onClose();
    addCardToDeck(props.adversary.name, props.adversary.id, c);
  };

  return (
    <CardCustomizationDialog
      adversary={props.adversary}
      isNew={true}
      quantity={1}
      upscaling={0}
      open={props.open}
      onClose={() => props.onClose()}
      onSave={handleSaveCustomization}
    />
  );
}
