import React, { useState } from "react";
import { Box, ButtonBase } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { HbAdversaryCreateDialog } from "./HbAdversaryCreateDialog";

export function HbAdversaryCreateButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}>
      <ButtonBase
        onClick={() => setIsOpen(true)}
        sx={{
          background: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          flexGrow: 1,
          height: '100%',
          textTransform: 'uppercase',
          fontWeight: 700,
          padding: 1,
          minHeight: '14em',
          flexDirection: 'column',
        }}
      >
        <AddIcon sx={{
          fontSize: '5em',
          opacity: 0.2,
        }} />
        <Box sx={{
          opacity: 0.65,
        }}>
          Create new <br/> adversary
        </Box>
      </ButtonBase>
      <HbAdversaryCreateDialog
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </Box>
  );
}
