import React, { useState } from 'react';
import { useSelectedDie } from "./hooks/useDiceSelection";
import { convert3DDiceResultToDiceRollEventInput, convertSelectedDiceToDiceNotation } from "./dice.util";
import { useModifier } from "./hooks/useModifer";
import { Button } from "@mui/material";
import { Dice } from "./diceBoxSetup";
import { usePlayerName } from "../player/hooks/usePlayerName";
import { DiceRollResultInput } from "../generated/graphql";

let cleanupTimeout: any = null;

export function DiceRollButton(props: {
  sendDiceRoll: (roll: DiceRollResultInput) => void
}) {
  const { sendDiceRoll } = props;

  const [selectedDie, setDice] = useSelectedDie();
  const [modifier, setModifier] = useModifier();
  const [pendingRoll, setPendingRoll] = useState(false);

  const [playerName] = usePlayerName();

  // (window as any).diceRoll = Dice;

  const rollDice = () => {
    // clear previous cleanupTimeout
    clearTimeout(cleanupTimeout);
    setPendingRoll(true);


    const diceNotations = convertSelectedDiceToDiceNotation(selectedDie);
    const rollModifier = modifier;

    setDice([]);
    setModifier(0);

    Dice.roll(diceNotations).then((results) => {
      setPendingRoll(false);
      cleanupTimeout = setTimeout(() => {
        Dice.clear();
      }, 3000);

      if (results[0].value === undefined) {
        // Something went wrong. This happens sometimes when .hide() is called.
        // Not calling that anymore.
        console.error('Error rolling dice', results);
        return;
      }

      const diceRollEvent = convert3DDiceResultToDiceRollEventInput(
        results,
        rollModifier,
        playerName,
      );

      sendDiceRoll(diceRollEvent);
    });
  };

  return (
    <Button
      disabled={pendingRoll || selectedDie.length === 0}
      color="secondary"
      variant="contained"
      sx={{
        fontWeight: 'bold',
      }}
      onClick={() => {
        rollDice();
      }}>
      Roll
    </Button>
  );
}





