import { Box } from '@mui/material';
import React from 'react';
import { useAdversaries } from './useAdversaries';
import { useDeck } from '../game-master/encounter/DeckBuilder/useDeck';
import { WelcomeText } from '../layout/WelcomeText';
import { AdversaryCardOverview } from './AdversaryCardOverview';
import HorizontalScroll from '../layout/HorizontalScroll';
import { BottomBar } from "../game-master/bottom-bar/BottomBar";
import { adversaryMainName } from "./Adversary/AdversaryName";
import { DeckCard } from "../game-master/encounter/DeckBuilder/Deck.types";
import { useAdversaryDiceRoll } from "./hooks/useAdversaryDiceRoll";

export function AdversaryList() {
  const { adversaries, findAdversary } = useAdversaries();
  const { deck, updateCardInDeck, addDiceResultToCard } = useDeck();
  const { rollDice } = useAdversaryDiceRoll();

  if (!adversaries) {
    return <div>Loading...</div>;
  }

  if (deck.length === 0) {
    return <WelcomeText/>;
  }

  const onDiceRoll = async (card: DeckCard, dice: string) => {
    const name = adversaryMainName(card?.name || '', card?.instances ?? [])
    const result = await rollDice(dice, name);

    if (result) {
      addDiceResultToCard(card.id, result);
    }
  }

  return (
    <HorizontalScroll>
      {deck.map((card, index) => (
        <Box key={card.id} className="no-scrollbar" sx={{
          overflowY: 'auto',
          width: '26em',
          minWidth: '26em',
          // Need a little padding right for the outline
          p: '1.5em 2px 1.5em 1.5em',
          '&:last-child': {
            mr: '1.5em',
          },
        }}>
          <Box mb={8}> {/* So that the card can be scrolled above the navigation arrows and bottom bar */}
            <AdversaryCardOverview
              key={index}
              elevation={2}
              adversary={findAdversary(card)!}
              card={card}
              onInstancesUpdate={(instances) => {
                updateCardInDeck(card.id, { ...card, instances });
              }}
              onDiceRoll={(result) => {
                onDiceRoll(card, result).then();
              }}
            />
          </Box>
        </Box>
      ))}
      <BottomBar/>
    </HorizontalScroll>
  );
}
