import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PlayerInvite } from "../menu-items/PlayersButton";
import { SettingsPanel } from "../../settings/SettingsPanel";
import { AuthGuard } from "./AuthGuard";
import { HomebrewCatalog } from "../../homebrew-adversaries/HomebrewCatalog";
import { InfoText } from "../../../layout/InfoText";
import { EncountersCatalog } from "../../encounter/EncountersCatalog";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import UserIcon from "@mui/icons-material/Group";
import ConstructionIcon from '@mui/icons-material/Construction';
import TryIcon from '@mui/icons-material/Try';
import { useSettings } from "../../hooks/useSettings";
import { Header2 } from "../../../common/typography/Header2";
import { Paper } from "@mui/material";

interface Props {
  close: () => void;
}

/**
 * A modal with a tab-sidebar
 */
export function GmMainMenuTabs(props: Props) {
  const [value, setValue] = React.useState('enc');
  const { settings } = useSettings();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      height: "100%",
    }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          borderRight: 2,
          background: '#fff',
          borderColor: 'divider',
        }}
      >
        <Tab label={<><TryIcon /> Encounters</>} {...a11yProps('enc')} />
        {/*{settings.useExperimentalFeatures && (*/}
        <Tab label={<><ConstructionIcon /> Homebrew</>} {...a11yProps('hb')} />
        {/*)}*/}
        <Tab label={<><UserIcon /> Players</>} {...a11yProps('players')} />
        <Tab label={<><SettingsIcon /> Settings</>} {...a11yProps('settings')} />
        <Tab label={<><InfoIcon /> Info</>} {...a11yProps('info')} />
      </Tabs>
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        flexDirection: 'column',
      }}>
        <CustomTabPanel value={value} index='enc'>
          <EncountersCatalog close={props.close} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index='hb'>
          <AuthGuard>
            <HomebrewCatalog />
          </AuthGuard>
        </CustomTabPanel>
        <CustomTabPanel value={value} index='players'>
          <CenterContent>
            <PlayerInvite />
          </CenterContent>
        </CustomTabPanel>
        <CustomTabPanel value={value} index='settings'>
          <SettingsPanel />
        </CustomTabPanel>
        <CustomTabPanel value={value} index='info'>
          <CenterContent>
            <InfoText />
          </CenterContent>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

function a11yProps(index: string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index,
    sx: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      fontSize: '1em',
      textAlign: 'left !important',
      fontWeight: 'bold',
      pl: 2,
      pr: 3,
      // height: 'auto',
      // minHeight: '2em',
      alignItems: 'center',
      textTransform: 'none',
      gap: 1,
    }
  };
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const visible = value === index;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={visible ? {
        display: "flex",
        width: "100%",
        flexDirection: "column",
      } : {
        display: "none"
      }}
    >
      {visible && <>{children}</>}
    </Box>
  );
}

function CenterContent(props: {
  children: React.ReactNode
}) {
  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box p={2} maxWidth={800}>
        <Paper sx={{ p: 2 }}>
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
}
