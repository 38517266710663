import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, CardActionArea } from '@mui/material';
import React from 'react';
import { Adversary } from './Adversary.type';
import { AdversaryImage } from './Adversary/AdversaryImage';
import { AdversaryTypeWithDivider } from './Adversary/AdversaryType';
import { AdversaryName } from './Adversary/AdversaryName';
import { TierBadge } from './Adversary/TeirBadge';
import { AdversaryCardTop } from './Adversary/AdversaryCardTop';
import { TwoColumn } from './Adversary/TwoColumn';
import { AdversaryAttack } from './Adversary/AdversaryAttack';
import { AdversaryDefence } from './Adversary/AdversaryDefence';
import { AdversaryFeatures, AdversaryFeaturesCompact } from './Adversary/AdversaryFeatures';
import { AdversaryExperience } from './Adversary/AdversaryExperience';
import { CardDivider } from './Adversary/CardDivider';
import { MotivesAndTactics } from './Adversary/AdversaryMotivesAndTactics';
import { TopLeft } from './Adversary/TopLeft';
import { CardInstance, DeckCard } from '../game-master/encounter/DeckBuilder/Deck.types';
import {
  yellow,
} from '../core/muiTheme';
import { UpscaleBadge } from './Adversary/UpscaleBadge';
import { useSelectedCard } from '../game-master/sidebar/useSidebar';
import { useSettings } from "../game-master/hooks/useSettings";
import { InstanceInfo } from "./InstanceInfo";
import { AdversarySummon } from "./Adversary/AdversarySummon";
import { AdversaryDiceRolls } from "./AdversaryDiceRolls";
import { AdversaryDescription } from "./Adversary/AdversaryDescription";
import { isEnvironment } from "./adversary.util";
import { Environment } from "./Environment.type";
import { AdversaryDifficulty } from "./Adversary/AdversaryDifficulty";

// Define the type for the props to ensure type safety
interface Props {
  adversary?: Adversary | Environment;
  elevation?: number;
  card: Pick<DeckCard, 'id' | 'upscaling' | 'quantity' | 'instances' | 'rolls'>;
  onInstancesUpdate?: (instance: CardInstance[]) => void;
  onDiceRoll: (result: string) => void;
  showDescription?: boolean;
}

export function AdversaryCardOverview(props: Props) {
  const {
    adversary,
    elevation,
    card,
    onInstancesUpdate,
    onDiceRoll,
  } = props;
  const upscaling = card?.upscaling ?? 0;
  const { selectedCard, setSelectedCard } = useSelectedCard();

  if (!adversary) {
    return (
      <Card sx={{ borderRadius: 2 }} elevation={elevation ?? 1}>
        <CardContent>Adversary not found</CardContent>
      </Card>
    );
  }

  const selectCard = () => {
    if (selectedCard === card.id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(card.id || null);
    }
  };

  const summons = adversary.features?.filter(f => f.summon)
    .flatMap(f => f.summon!) || [];

  return (
    <Card elevation={elevation ?? 1} sx={{
      borderRadius: 2,
      outline: selectedCard === card.id ? `2px solid ${yellow[500]}` : undefined,
      // boxShadow: selectedCard === card.id ? `0 0 20px 0 ${blue[300]}` : undefined,
    }}>
      <AdversaryCardTop>
        <CardActionArea onClick={selectCard}>
          <TopLeft>
            <TierBadge tier={adversary.tier}/>
            <UpscaleBadge upscale={card?.upscaling}/>
          </TopLeft>
          <AdversaryImage adversary={adversary}/>
        </CardActionArea>
        <AdversaryTypeWithDivider type={adversary.type} isEnv={isEnvironment(adversary)}/>
        <AdversaryDiceRolls rolls={card?.rolls || []}/>
      </AdversaryCardTop>
      <CardContent>
        <AdversaryName name={adversary.name} instances={card.instances}/>
        {props.showDescription && (
          <AdversaryDescription>{adversary.shortDescription}</AdversaryDescription>
        )}
        {!isEnvironment(adversary) ? (
          <AdversaryCardContent
            selectCard={selectCard}
            adversary={adversary}
            upscaling={upscaling}
            card={card}
            onInstancesUpdate={onInstancesUpdate}
            onDiceRoll={onDiceRoll}
          />
        ) : (
          <EnvironmentCardContent
            selectCard={selectCard}
            adversary={adversary}
            upscaling={upscaling}
            onDiceRoll={onDiceRoll}
          />
        )}
        {summons.length > 0 && (
          <>
            <CardDivider fontSize="0.8em" m={1.75}>Summons</CardDivider>
            <AdversarySummon summons={summons} upscaling={card.upscaling}/>
          </>
        )}
      </CardContent>
    </Card>
  );
}

function AdversaryCardContent(props: {
  selectCard: () => void;
  onDiceRoll: (result: string) => void;
  onInstancesUpdate: ((instance: CardInstance[]) => void) | undefined,
  upscaling: number,
  adversary: (Adversary & { attackModifier: string }) | Adversary,
  card: Pick<DeckCard, "id" | "upscaling" | "quantity" | "instances" | "rolls">
}) {
  const {
    upscaling,
    onDiceRoll,
    adversary,
    card,
    onInstancesUpdate,
    selectCard,
  } = props;

  const { settings } = useSettings();

  return (
    <>
      <AdversaryAttack
        adversary={adversary}
        upscaling={upscaling}
        onDiceRoll={onDiceRoll}
      />
      <TwoColumn>
        <AdversaryExperience experience={adversary.experience}/>
        {settings.detailedView && (
          <MotivesAndTactics adversary={adversary}/>
        )}
      </TwoColumn>
      {(adversary.features && adversary.features.length > 0) && (
        <>
          <CardDivider fontSize="0.8em" m={1.75}>Features</CardDivider>
          <Box m={-2}>
            <CardActionArea onClick={selectCard}>
              <Box m={2}>
                {settings.detailedView ? (
                  <AdversaryFeatures
                    features={adversary.features}
                    upscaling={upscaling}
                    onDiceRoll={onDiceRoll}
                  />
                ) : (
                  <AdversaryFeaturesCompact features={adversary.features}/>
                )}
              </Box>
            </CardActionArea>
          </Box>
        </>
      )}
      <CardDivider fontSize="0.8em" m={1.75}>HP &amp; Stress</CardDivider>
      <AdversaryDefence adversary={adversary} upscaling={upscaling}/>
      {card?.instances?.map((instance, i) => (
        <Box key={i} mt={1}>
          <InstanceInfo
            adversary={adversary}
            instance={instance}
            index={i}
            showName={card.instances.length > 1}
            onUpdate={(newInstance) => {
              const newInstances = [...card.instances];
              newInstances[i] = newInstance;
              onInstancesUpdate && onInstancesUpdate(newInstances);
            }}
          />
        </Box>
      ))}
    </>
  );
}

function EnvironmentCardContent(props: {
  onDiceRoll: (result: string) => void,
  selectCard: () => void,
  upscaling: number,
  adversary: Environment,
}) {
  const {
    upscaling,
    onDiceRoll,
    adversary,
    selectCard,
  } = props;

  const { settings } = useSettings();

  return (
    <>
      <Box mb={1}>
        <AdversaryDifficulty baseTeir={adversary.tier || 1} difficulty={adversary.difficulty} upscaling={upscaling} />
      </Box>
      <CardDivider fontSize="0.8em" m={1.75}>Features</CardDivider>
      <Box m={-2}>
        <CardActionArea onClick={selectCard}>
          <Box m={2}>
            {settings.detailedView ? (
              <AdversaryFeatures
                features={adversary.features}
                upscaling={upscaling}
                onDiceRoll={onDiceRoll}
              />
            ) : (
              <AdversaryFeaturesCompact features={adversary.features}/>
            )}
          </Box>
        </CardActionArea>
      </Box>
    </>
  );
}

