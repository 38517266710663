import { SelectedDie } from "./hooks/useDiceSelection";
import { DiceNotation, DiceRollResult } from "@3d-dice/dice-box";
import { baseGreen, blue, purple, red, yellow } from "../core/muiTheme";
import { DiceRollInput, DiceRollResultInput } from "../generated/graphql";

export function convertSelectedDiceToDiceNotation(dice: SelectedDie[]): DiceNotation[] {
  return dice.map((die) => {
    return {
      qty: 1,
      sides: die.sides,
      data: die.type,
      themeColor: diceColor(die.type),
      mods: [],
    };
  });
}

export function diceColor(type: string) {
  switch (type) {
    case 'hope':
      return yellow[500];
    case 'fear':
      return blue[500];
    case 'advantage':
      return baseGreen;
    case 'disadvantage':
      return red[500];
    default:
      return purple[400];
  }
}

export function convert3DDiceResultToDiceRollEventInput(
  results: DiceRollResult[],
  modifier: number,
  playerName: string
): DiceRollResultInput {
  const hopeResult = results.find((result) => result.data === 'hope');
  const fearResult = results.find((result) => result.data === 'fear');

  const diceRollsInputs: DiceRollInput[] = results.map((result) => {
    return {
      sides: result.sides,
      type: result.data,
      value: result.value,
    };
  });

  // calculate total (sum of all dice rolls, disadvantage should reduce the total)
  const total = diceRollsInputs.reduce((acc, result) => {
    return result.type === 'disadvantage' ? acc - result.value : acc + result.value;
  }, 0);

  return {
    playerName: playerName,
    diceRolls: diceRollsInputs,
    modifier: modifier,
    total: total + modifier,
    withHope: hasHope(hopeResult, fearResult),
    isCrit: isCrit(hopeResult, fearResult),
  };
}

function hasHope(hopeResult: DiceRollResult | undefined, fearResult: DiceRollResult | undefined) {
  if (hopeResult && fearResult) {
    return hopeResult.value >= fearResult.value;
  }

  return undefined;
}

function isCrit(hopeResult: DiceRollResult | undefined, fearResult: DiceRollResult | undefined) {
  if (hopeResult && fearResult) {
    return hopeResult.value == fearResult.value;
  }

  return undefined;
}
