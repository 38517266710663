import React from 'react';
import { NumberInput } from "../common/input/NumberInput";
import { useModifier } from "./hooks/useModifer";
import { Box, Tooltip } from "@mui/material";

export function ModifierInput() {
  const [modifier, setModifier] = useModifier();

  return (
    <Box sx={{
      '& input': {
        width: '2.5rem',
        padding: '6px',
      },
    }}>
      <Tooltip title="Modifier" disableInteractive placement="top">
        <div>
          <NumberInput aria-label="Modifier" value={modifier} onChange={(v) => setModifier(v || 0)} />
        </div>
      </Tooltip>
    </Box>
  );
};

