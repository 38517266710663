import { createTheme } from '@mui/material';

// export const red = '#ca0001';
// export const blue = '#2978be';
// export const purple = '#1e184d'

export const baseDarkBlue = '#0a0f21';
export const basePurple = '#1e184d';
export const basePurpleLight = '#38227b';
export const baseGold = '#f3c267';
export const baseGoldLight = '#fef790';
export const baseGoldDark = '#e38c3e';
export const baseTeal = '#81ccc3';
export const baseBlue = '#2878be';
export const baseOffWhite = '#F2F3F4';
export const baseGrey = '#eee';

export const lightGrey = '#787a7c'; // From pdf manual

export const baseGreen = '#2e7d32';

export const blue = {
  900: '#013D72',
  800: '#074C88',
  700: '#105B9D',
  600: '#1B6AAF',
  500: '#2878be',
  400: '#3D89CC',
  300: '#549AD8',
  200: '#6DABE2',
  100: '#87BCEB',
  50: '#A3CDF2',
};


// https://hihayk.github.io/scale/#4/6/50/85/0/0/50/50/9DA8B7/157/168/183/white
export const grey = {
  900: '#4B545F',
  800: '#5F6975',
  700: '#737E8C',
  600: '#8893A2',
  500: '#9DA8B7',
  400: '#AAB4C2',
  300: '#B7C0CD',
  200: '#C5CDD8',
  100: '#D3D9E2',
  50: '#E1E5EC',
};

// https://hihayk.github.io/scale/#4/6/50/65/0/0/50/50/1e184d/30/24/77/white
export const purple = {
  900: '#0A052D',
  800: '#0E0936',
  700: '#130D3F',
  600: '#181246',
  500: '#1e184d',
  400: '#352F62',
  300: '#4C4777',
  200: '#645F8B',
  100: '#7C779F',
  50: '#9490B3',
};

export const red = {
  900: '#7E0000',
  800: '#960000',
  700: '#AA0000',
  600: '#BC0000',
  500: '#ca0001',
  400: '#D92626',
  300: '#E64D4D',
  200: '#F07373',
  100: '#F79999',
  50: '#FCBFBF',
};

// #ff5f57

export const friendlyRed = {
  900: '#801D17',
  800: '#9F2A23',
  700: '#BF3932',
  600: '#DF4B43',
  500: '#ff5f57',
  400: '#FF726A',
  300: '#FF8680',
  200: '#FF9D97',
  100: '#FFB5B1',
  50: '#FFCFCC',
};

// https://hihayk.github.io/scale/#4/6/50/90/0/0/0/0/f3c267/243/194/103/white
export const yellow = {
  900: '#806622',
  800: '#9F7E30',
  700: '#BF9540',
  600: '#DCAC53',
  500: '#f3c267',
  400: '#FACD79',
  300: '#FFD78C',
  200: '#FFE0A2',
  100: '#FFE9B9',
  50: '#FFF2D2',
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: purple[500],
    },
    warning: {
      main: yellow[500],
    },
    error: {
      main: red[500],
    },
  },
});
