import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { EncounterBackground } from './encounter/EncounterBackground';
import { TopBar } from './top-bar/TopBar';
import { AdversaryList } from '../adversaries/AdversaryList';
import { AdversarySidebar } from './encounter/AdversarySidebar';
import { useSelectedCard } from './sidebar/useSidebar';
import { useSettings } from "./hooks/useSettings";

function GamerMasterView() {
  const { selectedCard } = useSelectedCard();
  const { settings } = useSettings();

  useEffect(() => {
    (document as any).getElementById("viewport-meta").setAttribute('content', 'width=1024')
  })

  return (
    <div className="App">
      <EncounterBackground/>
      <Box display="flex" flexDirection="row" height="100%" overflow="hidden">
        <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
          <TopBar/>
          <Box sx={{
            display: 'flex', flexGrow: 1, overflow: 'hidden', fontSize: `${13 + settings.cardSize - 5}px`,
          }}>
            <AdversaryList/>
          </Box>

        </Box>
        {(selectedCard !== null) && (
          <Box sx={{
            fontSize: '15px',
            // background: "#ff0000"
          }}>
            <AdversarySidebar/>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default GamerMasterView;
