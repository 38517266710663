import { Adversary } from "../../adversaries/Adversary.type";
import React, { useState } from "react";
import { DeckCatalogGridAdversary } from "../encounter/DeckBuilder/DeckCatalog/DeckCatalogGridAdversary";
import Dialog from "@mui/material/Dialog";
import { HbAdversaryEditDialog } from "./HbAdversaryEditDialog";

interface Props {
  adversary: Adversary;
}

export function HbAdversaryGridItem({ adversary }: Props) {
  const [customizationOpen, setCustomizationOpen] = useState(false);

  return (
    <>
      <DeckCatalogGridAdversary
        adversary={adversary}
        onClick={() => setCustomizationOpen(true)}
      />
      {customizationOpen && (
        <Dialog open={true} onClose={() => setCustomizationOpen(false)}>
          <HbAdversaryEditDialog
            open={true}
            adversary={adversary}
            handleClose={() => setCustomizationOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
}
