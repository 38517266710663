import { Box } from '@mui/material';
import React, { useState } from "react";
import { useAdversaries } from "../useAdversaries";
import { CardCustomizationDialog } from "../../game-master/encounter/CardCustomization/CardCustomizationDialog";
import { useDeck } from "../../game-master/encounter/DeckBuilder/useDeck";
import { DeckCardUpdateProps } from "../../game-master/encounter/DeckBuilder/Deck.types";
import Button from "@mui/material/Button";
import { AdversaryFeatureSummon } from "../../generated/graphql";

interface AdversarySummonProps {
  summons: AdversaryFeatureSummon[];
  upscaling: number;
}

export function AdversarySummon(props: AdversarySummonProps) {
  const { summons, upscaling } = props;

  return (
    <Box sx={{
      fontSize: '1em',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '0.5em',
    }}>
      {summons.map((summon, index) => (
        <Box key={index}>
          <AdversarySummonButton
            adversaryName={summon.adversaryName}
            upscaling={upscaling}
            quantity={summon.quantity}
          />
        </Box>
      ))}
    </Box>
  );
}

function AdversarySummonButton(props: { adversaryName: string, upscaling: number, quantity: string }) {
  const { findAdversary } = useAdversaries();
  const adversary = findAdversary({ name: props.adversaryName })
  const [customizationOpen, setCustomizationOpen] = useState(false);

  const { addCardToDeck } = useDeck();

  const handleSaveCustomization = (c: DeckCardUpdateProps) => {
    addCardToDeck(props.adversaryName, undefined, c);
  };

  const handleCustomizationToggle = () => {
    setCustomizationOpen(!customizationOpen);
  };


  if (!adversary) {
    return null;
  }

  const quanitityIsNumeric = +props.quantity + '' === props.quantity

  const quantity = parseInt(props.quantity, 10) || 1;

  // Check if quantity is text. Then it should be shown as a message in the modal
  const quantityNotes = !quanitityIsNumeric ? props.quantity : undefined;

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={handleCustomizationToggle}
      >
        {props.adversaryName} {quanitityIsNumeric ? `x${quantity}` : `(${quantityNotes})`}
      </Button>
      {customizationOpen && (
        <>
          <CardCustomizationDialog
            adversary={adversary}
            open={true}
            isNew={true}
            quantity={quantity}
            quantityNotes={quantityNotes}
            upscaling={props.upscaling}
            onClose={() => setCustomizationOpen(false)}
            onSave={handleSaveCustomization}
          />
        </>
      )}
    </>
  );
}

