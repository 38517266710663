import { LoadingButton } from "@mui/lab";
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export function HbAdversaryDeleteButton(props: { loading: boolean, onDelete: () => void }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <>
      <LoadingButton
        loading={props.loading}
        variant="outlined"
        color="error"
        endIcon={<DeleteIcon />}
        onClick={() => setDeleteDialogOpen(true)}
      >
        Delete
      </LoadingButton>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this adversary?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={props.onDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}