import React from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useLocalStorage } from "usehooks-ts";

interface PlayerNameFormProps {
  onSubmit: (value: string) => void;
}

export function PlayerNameForm(props: PlayerNameFormProps) {
  const [name, setName] = useLocalStorage('playerName', '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const maxLength = 20;
  const minLength = 1;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name.length >= minLength && name.length <= maxLength) {
      props.onSubmit(name);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}
         sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <TextField
        color="secondary"
        label="Enter your name"
        variant="outlined"
        value={name}
        onChange={handleChange}
        required
        inputProps={{ minLength: minLength, maxLength: maxLength }}
      />
      <Button type="submit" variant="contained" color="secondary" disabled={name.length < minLength || name.length > maxLength}>
        Start rolling!
      </Button>
    </Box>
  );
}

