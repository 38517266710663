import { Box } from '@mui/material';
import { Adversary } from '../Adversary.type';
import { baseGrey, grey } from '../../core/muiTheme';

interface Props {
  adversary: Adversary;
  upscaling: number;
}

export function AdversaryDefence(props: Props) {
  const { adversary, upscaling } = props;
  const { damageThresholds, hitPoints, stress } = adversary;

  if (!damageThresholds) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box mr={1}>
        HP: <strong>{hitPoints}</strong> <br />
        Stress: <strong>{stress}</strong> <br />
      </Box>
      <Box
        display="flex" alignItems="center"
        sx={{
          '& > div:last-child > div:last-child': {
            // borderRightWidth: '1px',
            // borderRadius: '0 7px 7px 0',
            marginRight: '0',
            clipPath: `polygon(0% 0%, 80% 0%, 100% 35%, 100% 65%, 80% 100%, 0% 100%)`,
          },
          '& > div:first-child > div:first-child': {
            // borderRightWidth: '1px',
            // borderRadius: '0 7px 7px 0',
            marginLeft: '0',
            clipPath: `polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%, 0 65%, 0 35%)`,
          },
        }}
      >
        {/*{damageThresholds.minor ? (*/}
        {/*  <Box display="flex" alignItems="center">*/}
        {/*    <DefenceBox>{damageThresholds.minor}</DefenceBox>*/}
        {/*    <DefenceText>Minor</DefenceText>*/}
        {/*  </Box>*/}
        {/*) : null}*/}
        <Box display="flex" alignItems="center">
        <DefenceText>Minor</DefenceText>
        </Box>
        {damageThresholds.major ? (
          <Box display="flex" alignItems="center">
            <DefenceBox>{upscaleDefence(damageThresholds.major, upscaling)}</DefenceBox>
            <DefenceText>Major</DefenceText>
          </Box>
        ) : null}
        {damageThresholds.severe ? (
          <Box display="flex" alignItems="center">
            <DefenceBox>{upscaleDefence(damageThresholds.severe, upscaling)}</DefenceBox>
            <DefenceText>Severe</DefenceText>
          </Box>
        ) : null}
      </Box>

    </Box>
  );
}

function upscaleDefence(defence: number, tier: number) {
  return Math.floor(defence * 1.72 ** tier);
}

export function DefenceBox({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        clipPath: 'polygon(' +
          '0%    0%    ,' +
          '25%   0%    ,' +
          '30%   6%    ,' +
          '70%   6%    ,' +
          '75%   0%    ,' +
          '100%  0%    ,' +
          '100%  25%   ,' +
          '94%   30%   ,' +
          '94%   70%   ,' +
          '100%  75%   ,' +
          '100%  100%  ,' +
          '75%   100%  ,' +
          '70%   94%   ,' +
          '30%   94%   ,' +
          '25%   100%  ,' +
          '0%    100%  ,' +
          '0%    75%   ,' +
          '6%    70%   ,' +
          '6%    30%   ,' +
          '0%    25%   );',
        background: grey[800],
        fontWeight: 'bold',
        borderRadius: '3px',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          clipPath: 'polygon(' +
            'calc(0%   + 1.0px) calc(0%    + 1.0px) ,' +
            'calc(25%  - 0.5px) calc(0%    + 1.0px) ,' +
            'calc(30%  - 0.5px) calc(6%    + 1.0px) ,' +
            'calc(70%  + 0.5px) calc(6%    + 1.0px) ,' +
            'calc(75%  + 0.5px) calc(0%    + 1.0px) ,' +
            'calc(100% - 1.0px) calc(0%    + 1.0px) ,' +
            'calc(100% - 1.0px) calc(25%   - 0.5px) ,' +
            'calc(94%  - 1.0px) calc(30%   - 0.5px) ,' +
            'calc(94%  - 1.0px) calc(70%   + 0.5px) ,' +
            'calc(100% - 1.0px) calc(75%   + 0.5px) ,' +
            'calc(100% - 1.0px) calc(100%  - 1.0px) ,' +
            'calc(75%  + 0.5px) calc(100%  - 1.0px) ,' +
            'calc(70%  + 0.5px) calc(94%   - 1.0px) ,' +
            'calc(30%  - 0.5px) calc(94%   - 1.0px) ,' +
            'calc(25%  - 0.5px) calc(100%  - 1.0px) ,' +
            'calc(0%   + 1.0px) calc(100%  - 1.0px) ,' +
            'calc(0%   + 1.0px) calc(75%   + 0.5px) ,' +
            'calc(6%   + 1.0px) calc(70%   + 0.5px) ,' +
            'calc(6%   + 1.0px) calc(30%   - 0.5px) ,' +
            'calc(0%   + 1.0px) calc(25%   - 0.5px) );',
          background: baseGrey,
          borderRadius: '5px',
          width: '2.5em',
          height: '2.5em',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export function DefenceText({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        fontSize: '0.7em',
        fontWeight: 'bold',
        borderStyle: 'solid',
        // borderTopWidth: '1px',
        // borderBottomWidth: '1px',
        // borderRightWidth: 0,
        // borderLeftWidth: 0,
        // borderColor: grey[700],
        lineHeight: '1.8em',
        width: '5.1em',
        background: grey[800],
        textAlign: 'center',
        color: 'white',
        margin: '0 -0.8em',
      }}
    >
      {children}
    </Box>
  );
}
