import { useDiceRollHistory } from "../dice/hooks/useDiceRollHistory";
import { DiceRollResultItem } from "../dice/DiceRollResultItem";
import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { purple } from "../core/muiTheme";

export function DiceRollHistory() {
  const [diceRollEvents] = useDiceRollHistory();

  return (
    <>
      {diceRollEvents.map((message, index) => (
        <Box key={index} sx={{
          '&:last-child': {
            pb: '0.75em',
          },
        }}>
          <Box sx={{
            margin: '0.5em 0.75em',
          }}>
            <DiceRollResultItem result={message.data.diceRollResult} timestamp={message.timestamp}/>
          </Box>
          {index == diceRollEvents.length - 1 && (
            <ScrollIntoView/>
          )}
        </Box>
      ))}
    </>
  );
}

export function LastDiceRoll() {
  const [diceRollEvents] = useDiceRollHistory();

  if (diceRollEvents.length === 0) {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          border: '1px solid',
          padding: '0.5em',
          fontWeight: 700,
          overflow: 'hidden',
          backgroundColor: purple[300],
          borderColor: purple[100],
          color: 'white',
        }}
      >
        No dice rolls yet
      </Box>
    );
  }

  const lastRoll = diceRollEvents[diceRollEvents.length - 1];

  return (
    <DiceRollResultItem result={lastRoll.data.diceRollResult} timestamp={lastRoll.timestamp}/>
  );
}


const ScrollIntoView = () => {
  const divRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  // Wait for the roll to be rendered and then scroll into view
  // const onNewRoll = () => {
  //   setTimeout(() => {
  //     if (divRef.current) {
  //       divRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 10)
  // }

  // // Listen when there is a new dice roll
  // useEffect(() => {
  //   const subscription = diceRollHistorySubject.subscribe(onNewRoll);
  //   return () => subscription.unsubscribe();
  // }, []);

  return <div ref={divRef}/>;
}
