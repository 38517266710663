import React from 'react';
import Dialog from '@mui/material/Dialog';
import EditNoteIcon from '@mui/icons-material/EditNote';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import { EncounterBuilderDialog } from '../../encounter/DeckBuilder/EncunterBuilderDialog';
import { useDeck } from "../../encounter/DeckBuilder/useDeck";
import { BehaviorSubject } from "rxjs";
import { useBehaviorSubject } from "../../../common/hooks/useSubject";
import { baseOffWhite } from "../../../core/muiTheme";

export const isOpenSubject = new BehaviorSubject<boolean>(false);


export function EncounterBuilderButton() {
  const [open, setOpen] = useBehaviorSubject(isOpenSubject);
  const { deck } = useDeck();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {(deck?.length > 0) ? (
        <Button onClick={handleClickOpen} startIcon={<EditNoteIcon/>}>
          Edit Encounter
        </Button>
      ) : (
        <Button onClick={handleClickOpen} startIcon={<StarIcon/>}>
          Build Encounter
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl" PaperProps={{
        sx: {
          // background: 'none',
          // boxShadow: 'none',
          // borderRadius: 0,
          borderRadius: 0,
          borderWidth: 2,
          borderColor: 'warning.main',
          borderStyle: 'solid',
          backgroundColor: baseOffWhite,
        },
      }
      }>
        <EncounterBuilderDialog close={handleClose}/>
      </Dialog>
    </div>
  );
}


