import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";


export function DeckCatalogGrid(props: PropsWithChildren<{}>) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
      justifyContent: 'stretch',
      overflow: 'hidden',
      fontSize: 12,
    }}>
      <Box sx={{
        flex: 4,
        overflowY: 'auto',
        px: 1,
        pb: 1,
        height: '100%',
      }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: 1.5,
            padding: 1,
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
