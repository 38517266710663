import { useBehaviorSubject } from "../common/hooks/useSubject";
import { ablyLastErrorSubject, ablyStateSubject } from "../core/ablyClient";
import { Box } from "@mui/material";
import { useState } from "react";

export function AblyStatus() {
  const [ablyState] = useBehaviorSubject(ablyStateSubject);
  const [ablyLastError] = useBehaviorSubject(ablyLastErrorSubject);
  const [showDetails, setShowDetails] = useState(false);

  if (ablyState === null) {
    return null;
  }

  return (
    <Box sx={{
      position: 'fixed',
      top: '0',
      right: '0',
      padding: '0.2em 0.5em',
      background: showDetails ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)',
      color: showDetails ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.5)',
      fontSize: '10px',
      zIndex: 3,
    }} onClick={() => setShowDetails(!showDetails)}>
      {showDetails ? (
        <>
          <pre>{JSON.stringify(ablyState, null, 2)}</pre>
          {ablyLastError && (
            <>
              Last failed state:
              <pre>{JSON.stringify(ablyLastError, null, 2)}</pre>
            </>
          )}
        </>
      ) : (
        <>{ablyState.current}</>
      )}
    </Box>
  )
}