import { Box } from '@mui/material';
import { Adversary } from '../Adversary.type';

export function MotivesAndTactics(props: { adversary: Adversary }) {
  const { motivesAndTactics } = props.adversary;

  if (!motivesAndTactics || motivesAndTactics.length === 0) {
    return null;
  }

  return (
      <Box sx={{
        // fontSize: '0.9em',
      }}>
        <strong>Motives & Tactics:</strong>
        <Box sx={{
          fontStyle: 'italic',
        }}>
          {motivesAndTactics.join(', ')}
        </Box>
      </Box>
  );
}
