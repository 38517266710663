import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';

export function Header2(props: PropsWithChildren<{ sx?: SxProps }>) {
  return (
    <Box sx={{
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: '900',
      ...props.sx,
    }}>
      {props.children}
    </Box>
  );
}
