import React from 'react';
import { useForm, Control, UseFormWatch } from 'react-hook-form';
import {
  Button,
  Box,
  Paper,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Tooltip,
  Alert
} from '@mui/material';
import { adversaryTypeEnum, adversaryLocationEnum, attackRangeEnum, tagsEnum } from './config/enums';
import { AdversaryInput } from "../../generated/graphql";
import {
  FormInputNumber,
  FormInputSelect,
  FormInputSwitch,
  FormInputText,
  FormInputTier
} from "./HbAdversaryFormInputs";
import { CardDivider } from "../../adversaries/Adversary/CardDivider";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import { AdversaryFormPreview } from "./AdversaryFormPreview";
import CloseIcon from "@mui/icons-material/Close";
import { formDataToInput } from "./adversary-form.util";
import { HbAdversaryDeleteButton } from "./HbAdversaryDeleteButton";
import { HbAdversaryFeatureForm } from "./HbAdversaryFeatureForm";
import { AdversaryFormData } from "./HbAdversaryFormData.types";

// export interface AdversaryFormData {
//   name: string;
//   tags: string[];
//   locations: string[];
//   image: string;
//   shortDescription: string;
//   motivesAndTactics: string[];
//   tier?: number;
//   type: string;
//   hordeUnitsPerHp?: number;
//   difficulty: number;
//   attackModifier: string;
//   weapon: {
//     name: string;
//     range: string;
//     damage: string;
//   };
//   damageThresholds?: {
//     minor?: number;
//     major?: number;
//     severe?: number;
//   };
//   hitPoints: number;
//   stress: number;
//   experience?: string[];
//   features: {
//     name: string;
//     type: string;
//     description: string;
//     flavourText?: string;
//     value?: number;
//     attackModifier?: string;
//     uses?: number;
//     cost?: {
//       actions?: number;
//       fear?: number;
//       stress?: number;
//       hitPoints?: number;
//     };
//     summon?: {
//       adversaryName: string;
//       quantity: string;
//     }[];
//   }[];
// }

interface AdversaryFormProps {
  isNew?: boolean;
  defaultData: AdversaryInput;
  onSubmit: (data: AdversaryInput) => void;
  loading?: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

function Row(props: { children: React.ReactNode }) {
  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      flexWrap: 'wrap',
      mb: 3,
    }}>{props.children}</Box>
  );
}

export const HbAdversaryForm: React.FC<AdversaryFormProps> = (props) => {
  const { defaultData, onSubmit, loading } = props;
  const methods = useForm<AdversaryFormData>({
    defaultValues: {
      ...defaultData,
      experience: defaultData.experience?.join(', ') || '',
      motivesAndTactics: defaultData.motivesAndTactics.join(', '),
    },
  });

  const { control, handleSubmit, watch } = methods;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data) => {
        onSubmit(formDataToInput(data));
      })}
      noValidate
      sx={{
        width: 920,
        maxWidth: '100vw',
        position: 'relative',
        '& .card-divider': {
          opacity: 0.4,
        },
        px: 2,
      }}
    >
      <Box sx={{
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
      }}>
        <Box flex={1} flexGrow={1} width={400} height="100vh" py={4}>
          <Paper sx={{
            mb: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }} variant="outlined">
            <DialogTitle sx={{ py: 1.5 }}>
              {props.isNew ? 'New Adversary' : 'Edit Adversary'}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{
              overflowY: 'auto',
            }}>
              <FormFields control={control} watch={watch} />
            </DialogContent>
            <DialogActions>
              {props.onDelete && (
                <HbAdversaryDeleteButton loading={!!props.loading} onDelete={props.onDelete} />
              )}
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                type="submit"
                endIcon={<CheckIcon />}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Paper>
        </Box>
        <Box flex={1} maxWidth="26em" minWidth="23em" fontSize="12px">
          <Box className="no-scrollbar" sx={{
            maxHeight: '100vh',
            overflowY: 'auto',
            py: 4,
          }}>
            <AdversaryFormPreview watch={watch} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function FormFields(props: {
  watch: UseFormWatch<AdversaryInput & { experience: string; motivesAndTactics: string }>,
  control: Control<AdversaryInput & { experience: string; motivesAndTactics: string }, any>
}) {
  const { watch, control } = props;

  const selectedType = watch('type');

  const isPublic = watch('public');

  return (
    <>
      <Row>
        <Box flexGrow={1}>
          <FormInputText name="name" control={control} label="Name" required />
        </Box>
        <Box>
          <Tooltip title="Visible to all users, if approved by the admin" placement="top">
            <span>
              <FormInputSwitch name="public" control={control} label="Public" />
            </span>
          </Tooltip>
        </Box>
      </Row>
      <PublicMessage isPublic={isPublic} />
      <Row>
        <Box flex={1} minWidth={90}>
          <FormInputSelect name="type" control={control} label="Role" options={adversaryTypeEnum} />
        </Box>
        <Box flex={1} minWidth={90}>
          <FormInputNumber name="difficulty" control={control} label="Difficulty" valueAsNumber />
        </Box>
        <Box flex={1} minWidth={90}>
          <FormInputTier control={control} />
        </Box>
      </Row>
      {selectedType == 'Horde' && (
        <Row>
          <Box>
            <FormInputNumber name="hordeUnitsPerHp" control={control} label="Horde Units Per HP" valueAsNumber />
          </Box>
        </Row>
      )}
      <Row>
        <FormInputText name="experience" control={control} label="Experience"
                       helperText="Comma separated values" />
      </Row>
      <Row>
        <FormInputText name="motivesAndTactics" control={control} label="Motives and Tactics"
                       helperText="Comma separated values" />
      </Row>
      <Row>
        <Box minWidth={200} flex={1}>
          <FormInputSelect name="tags" control={control} label="Tags" options={tagsEnum} multiple />
        </Box>
        <Box minWidth={200} flex={1}>
          <FormInputSelect name="locations" control={control} label="Biome" options={adversaryLocationEnum}
                           multiple />
        </Box>
      </Row>
      <Row>
        <FormInputText name="image" control={control} label="Image URL" helperText="Recommended size 640 x 400" />
      </Row>
      {isPublic && (
        <Row>
          <FormInputText name="imageCredit" control={control} label="Image Credit" helperText="Artist or AI used for the image. Make sure that you have the rights to share this image" />
        </Row>
      )}
      <Row>
        <FormInputText name="shortDescription" control={control} label="Short Description" multiline />
      </Row>

      <CardDivider fontSize="0.8em" m={4}>Attack</CardDivider>

      <Row>
        <Box flex={3} minWidth={100}>
          <FormInputText name="weapon.name" control={control} label="Weapon Name" />
        </Box>
        <Box flex={2} minWidth={100}>
          <FormInputSelect name="weapon.range" control={control} label="Range" options={attackRangeEnum} />
        </Box>
        <Box flex={2} minWidth={100}>
          <FormInputText name="weapon.damage" control={control} label="Damage" />
        </Box>
        <Box flex={2} minWidth={100}>
          <FormInputNumber name="attackModifier" control={control} label="Attack Modifier" />
        </Box>
      </Row>
      <CardDivider fontSize="0.8em" m={4}>Damage Thresholds</CardDivider>
      <Row>
        <Box flex={1} minWidth={70}>
          <FormInputNumber name="damageThresholds.minor" control={control} label="Minor" valueAsNumber />
        </Box>
        <Box flex={1} minWidth={70}>
          <FormInputNumber name="damageThresholds.major" control={control} label="Major" valueAsNumber />
        </Box>
        <Box flex={1} minWidth={70}>
          <FormInputNumber name="damageThresholds.severe" control={control} label="Severe" valueAsNumber />
        </Box>
      </Row>
      <CardDivider fontSize="0.8em" m={4}>HP & Stress</CardDivider>
      <Row>
        <Box flex={1}>
          <FormInputNumber name="hitPoints" control={control} label="Hit Points" valueAsNumber />
        </Box>
        <Box flex={1}>
          <FormInputNumber name="stress" control={control} label="Stress" valueAsNumber />
        </Box>
      </Row>

      <CardDivider fontSize="0.8em" m={4}>Features</CardDivider>

      <Box>
        <HbAdversaryFeatureForm
          control={control}
          watch={watch}
        />
      </Box>
    </>
  );
}

function PublicMessage(props: { isPublic: any }) {
  if (!props.isPublic) return null;
  return (
    <Box mb={3}>
      <Alert severity="info">
        By making this adversary public you agree that it will be reviewed and shared publicly, on this and other
        platforms.
      </Alert>
    </Box>
  );
}
