import { useLocalStorage } from "usehooks-ts";
import { jwtDecode } from "jwt-decode";

export function useAuth() {
  const [token] = useLocalStorage<string | null>('token', null, {
    deserializer: (v) => v,
  });

  return {
    token,
    decodeToken: () => {
      const decoded = token ? jwtDecode(token) : null;

      return {
        data: decoded,
        isLogggedIn: decoded ? Date.now() < decoded.exp! * 1000 : false,
      };
    },
  };
}
