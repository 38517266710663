import React from "react";
import { useAuth } from "../../hooks/useAuth";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export function AuthGuard(props: { children: React.ReactNode }) {
  const { decodeToken } = useAuth();
  const token = decodeToken();

  if (token.isLogggedIn) {
    return <>{props.children}</>;
  }

  return (
    <Box
      sx={{
        padding: 2,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginButton/>
    </Box>
  );
}


export function LoginButton() {
  const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID!}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URI!)}&response_type=code&scope=identify`;

  return (
    <Button
      href={discordAuthUrl}
      variant="outlined"
      sx={{ color: 'black', borderColor: 'black' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Login with Discord
    </Button>
  );
}
