
interface Props {
  difficulty: number;
  upscaling: number;
  baseTeir: number;
}

export function AdversaryDifficulty(props: Props) {
  const { difficulty, upscaling, baseTeir } = props;
  const scaledDifficulty = scaleDifficulty(difficulty, upscaling, baseTeir || 0);

  return <>Difficulty: {scaledDifficulty}</>;
}

/**
 * The difficulty should increase by +2 per Tier from Tier 1 to Tier 3
 * and by +3 from Tier 3 to Tier 4.
 */
function scaleDifficulty(difficulty: number, upscaling: number, baseTier: number): number {
  const tier = baseTier + upscaling;
  const upscalingAbove3 = Math.max(0, Math.min(upscaling, tier - 3));

  return difficulty + upscaling * 2 + upscalingAbove3;
}
