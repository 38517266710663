import { BehaviorSubject } from 'rxjs';
import { useBehaviorSubject } from '../../common/hooks/useSubject';

const selectedCardSubject = new BehaviorSubject<null | number>(null);

export function useSelectedCard() {
  const [selectedCard, setSelectedCard] = useBehaviorSubject(selectedCardSubject);

  return {
    selectedCard,
    setSelectedCard,
  };
}
