import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  Box
} from '@mui/material';
import { useDebounceCallback } from 'usehooks-ts';
import { TierBadge } from "../../../adversaries/Adversary/TeirBadge";

interface FilterProps {
  categories: string[];
  biomes: string[];
  sources: string[];
  roles: string[];
  onFilterChange: (filters: FilterState) => void;
}

interface FilterState {
  search: string;
  category: string;
  biome: string;
  tiers: number[];
  source: string;
  role: string;
}

const teirs = [1, 2, 3, 4];

export const filterDefault: FilterState = {
  search: '',
  category: '',
  biome: '',
  source: '',
  role: '',
  tiers: []
}

const FilterComponent: React.FC<FilterProps> = (props) => {
  const { categories, biomes, onFilterChange, sources, roles } = props;
  const [search, setSearch] = useState(filterDefault.search);
  const [category, setCategory] = useState(filterDefault.category);
  const [biome, setBiome] = useState(filterDefault.biome);
  const [tiers, setTiers] = useState<number[]>(filterDefault.tiers);
  const [source, setSource] = useState(filterDefault.source);
  const [role, setRole] = useState(filterDefault.role);

  const debouncedSearch = useDebounceCallback((v: string) => {
    setSearch(v);
  }, 150);

  useEffect(() => {
    const filters: FilterState = { search, category, biome, tiers, source, role };
    onFilterChange(filters);
  }, [search, category, biome, tiers, source, role]);

  const handleTierChange = (tier: number, checked: boolean) => {
    if (checked) {
      setTiers((prevTiers) => [...prevTiers, tier]);
    } else {
      setTiers((prevTiers) => prevTiers.filter((t) => t !== tier));
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 1,
    }}>
      <TextField
        sx={{ flex: 1, flexGrow: 2, minWidth: 150 }}
        label="Search"
        size="small"
        autoComplete="off"
        onChange={(e) => {
          debouncedSearch(e.target.value as string);
        }}
      />
      <Box sx={{ flex: 1, minWidth: 100 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="deckfilter-type">Role</InputLabel>
          <Select
            labelId="deckfilter-role"
            value={role}
            label="Role"
            onChange={(e) => {
              setRole(e.target.value as string);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {roles.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ flex: 1, minWidth: 100 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="deckfilter-category">Category</InputLabel>
          <Select
            labelId="deckfilter-category"
            value={category}
            label="Category"
            onChange={(e) => {
              setCategory(e.target.value as string);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ flex: 1, minWidth: 100 }}>
        <FormControl
          sx={{ flex: 1, minWidth: 100 }}
          fullWidth
          size="small"
        >
          <InputLabel
             id="deckfilter-biome"
          >Biome</InputLabel>
          <Select
            value={biome}
            labelId="deckfilter-biome"
            size="small"
            label="Biome"
            onChange={(e) => {
              setBiome(e.target.value as string);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {biomes.map((bio) => (
              <MenuItem key={bio} value={bio}>
                {bio}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ flex: 1, minWidth: 100 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="deckfilter-source">Source</InputLabel>
          <Select
            labelId="deckfilter-source"
            value={source}
            label="Source"
            onChange={(e) => {
              setSource(e.target.value as string);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {sources.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <FormGroup row sx={{

        gap: 1,
        alignItems: 'center',
      }}>
        {teirs.map((tier) => (
          <TierCheckbox
            key={tier}
            tier={tier}
            checked={tiers.includes(tier)}
            onChange={(checked) => {
              handleTierChange(tier, checked)
            }}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default FilterComponent;

interface TierCheckboxProps {
  tier: number;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

//
// function TierCheckbox({ tier, checked, onChange }: TierCheckboxProps) {
//   return (
//     <FormControlLabel
//       control={
//         <Checkbox
//           checked={checked}
//           onChange={() => {
//             onChange(!checked);
//           }}
//           name={`tier-${tier}`}
//           color="primary"
//         />
//       }
//       label={`Tier ${tier}`}
//     />
//   );
// }


function TierCheckbox({ tier, checked, onChange }: TierCheckboxProps) {
  return (
    <Box
      sx={{
        fontSize: 12,
        opacity: checked ? 1 : 0.5,
        cursor: 'pointer',
      }}
      onClick={() => {
        onChange(!checked);
      }}
    >
      <TierBadge
        tier={tier}
      />
    </Box>
  );
}

