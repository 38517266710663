import DiceBox from "@3d-dice/dice-box";
import { diceColor } from "./dice.util";

export const Dice = new DiceBox(
  "#dice-box", // target DOM element to inject the canvas for rendering
  {
    // id: "dice-canvas", // canvas element id
    assetPath: "/assets/dice-box/",
    theme: "smooth",
    themeColor: diceColor("normal"),
    startingHeight: 8,
    gravity: 8,
    throwForce: 6,
    spinForce: 5,
    lightIntensity: 0.9
  }
);

// const DRP = new DiceParser();

// initialize the Dice Box outside of the component
Dice.init().then(() => {
  // clear dice on click anywhere on the screen
  // document.addEventListener("mousedown", () => {
  //   const diceBoxCanvas = document.getElementById("dice-canvas");
  //   if (window.getComputedStyle(diceBoxCanvas!).display !== "none") {
  //     Dice.hide().clear();
  //   }
  // });
});

let cleanupTimeout: any = null;

export function resetDiceCleanupTimer() {
  clearTimeout(cleanupTimeout);
}

export function clearDiceAfterTimeout(ms: number = 3000) {
  cleanupTimeout = setTimeout(() => {
    Dice.clear();
  }, 3000);
}
