import { useCreateAdversaryMutation } from "../generated/graphql";
import { adversaryExampleData } from "./adversary-example";


export function DevAdversaryCreate() {

  const [call, { error }] = useCreateAdversaryMutation();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <button onClick={() => call({
        variables: { input: adversaryExampleData }
      })}>Create Adversary
      </button>
    </div>
  );
}
