import { Box, Tooltip } from '@mui/material';
import BackHandIcon from '@mui/icons-material/BackHand';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import BoltIcon from '@mui/icons-material/Bolt';
import React from "react";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import { TextParser } from "./AdversaryFeatureTextParser";
import {
  AdversaryFeature,
  AdversaryFeatureMove
} from "../../generated/graphql";
import { EnvironmentFeature } from "../Environment.type";

type Feature = AdversaryFeature | EnvironmentFeature;

const toolTipProps: Partial<TooltipProps> = {
  arrow: true,
  placement: 'top-end',
  disableInteractive: true,
};

interface Props {
  features: Feature[];
  upscaling: number;
  onDiceRoll?: (dice: string) => void;
}

export function AdversaryFeatures(props: Props) {
  return (
    <Box sx={{
      fontSize: '1em',
    }}>
      {props.features.map((feature, index) => (
        <Box key={index} sx={{
          '&:not(:last-child)': {
            marginBottom: 1,
          },
        }}>
          <FeatureName feature={feature}/>
          <FeatureCost feature={feature}/>
          <FeatureAttackModifier value={feature.attackModifier}/>
          <FeatureUses value={feature.uses}/>
          <FeatureCountdown value={feature.countdown}/>
          <Box>
            <TextParser
              upscaling={props.upscaling}
              onDiceRoll={props.onDiceRoll}
              text={feature.description}
            />
            {/*{feature.description}*/}
          </Box>
          <FeatureMoves moves={feature.moves}/>
          {((feature as EnvironmentFeature).details) && (
            <FeatureDetails details={(feature as EnvironmentFeature).details}/>
          )}
          <Box sx={{
            fontStyle: 'italic',
            opacity: 0.8,
          }}>
            {feature.flavourText}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function FeatureAttackModifier(props: { value: number | string | undefined | null }) {
  if (props.value === undefined || props.value === null) {
    return null;
  }
  return (
    <Box fontStyle="italic">
      Attack modifier: {+props.value >= 0 ? '+' : ''}{props.value}
    </Box>
  );
}

function FeatureUses(props: { value: number | undefined | null }) {
  if (props.value === undefined || props.value === null) {
    return null;
  }
  return (
    <Box fontStyle="italic">
      Uses: {props.value}
    </Box>
  );
}

function FeatureCountdown(props: { value: string | undefined | null }) {
  if (props.value === undefined || props.value === null) {
    return null;
  }
  return (
    <Box fontStyle="italic">
      {!props.value.toLowerCase().includes('countdown') ? `Countdown: (${props.value})` : props.value}
    </Box>
  );
}

export function AdversaryFeaturesCompact(props: { features: Feature[] }) {
  if (!props.features) {
    return null;
  }

  return (
    <Box sx={{
      fontSize: '1em',
    }}>
      {props.features.map((feature, index) => (
        <Box key={index} sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '&:not(:last-child)': {
            marginBottom: 0.2,
          },
        }}>
          <FeatureName feature={feature}/>
          <FeatureCost feature={feature}/>
        </Box>
      ))}
    </Box>
  );
}

function FeatureName(props: { feature: Feature }) {
  const { feature } = props;

  return (
    <Box component="span" sx={{}}>
      <Box component="span" sx={{
        fontWeight: 'bold',
      }}>
        {feature.name}
        {(feature.value) ? (
          <span>&nbsp;{`(${feature.value})`}</span>
        ) : null}
      </Box>
      &nbsp;- <FeatureType1 feature={feature}/>
    </Box>
  );
}

function FeatureType1(props: { feature: Feature }) {
  return (
    <Box component="span" sx={{
      fontSize: '1em',
    }}>
      {props.feature.type}&nbsp;
    </Box>
  )
}

function FeatureCost(props: { feature: Feature }) {
  const { feature } = props;

  return (
    <Box component="span" sx={{
      display: 'inline-flex',
      textTransform: 'uppercase',
      alignItems: 'center',
      float: 'right',
      mt: '0.2em',
      '& > *': {
        marginLeft: '0.2em',
      },
    }}>
      {(feature.cost?.stress) && <StressCounter cost={feature.cost.stress}/>}
      {(feature.cost?.fear) && <FearCounter cost={feature.cost.fear}/>}
      {(feature.type === 'Action') && <ActionCounter cost={feature.cost?.actions || 1}/>}
      {(feature.type === 'Passive') && <PassiveMarker/>}
      {(feature.type === 'Reaction') && <ReactionMarker/>}
    </Box>
  );
}

function FearCounter(props: { cost: number }) {
  return (
    <Tooltip title={`${props.cost} Fear`} {...toolTipProps}>
      <Box display="flex" alignItems="center" gap="0.2em">
        {Array.from({ length: props.cost }, (_, index) => (
          <Box key={index}
               display="inline-block"
               sx={{
                 width: '1.4em',
                 height: '1.4em',
                 borderRadius: '50%',
                 background: '#000',
                 color: '#fff',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 fontSize: '0.8em',
                 fontWeight: 'bold',
                 border: '1px solid #000',
               }}
          >F</Box>
        ))}
      </Box>
    </Tooltip>
  );
}

function StressCounter(props: { cost: number }) {
  return (
    <Tooltip title={`${props.cost} Stress`} {...toolTipProps}>
      <Box display="flex" alignItems="center" gap="0.2em">
        {Array.from({ length: props.cost }, (_, index) => (
          <Box key={index}
               display="inline-block"
               sx={{
                 width: '1.4em',
                 height: '1.4em',
                 borderRadius: '50%',
                 background: '#fff',
                 color: '#000',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 fontSize: '0.8em',
                 fontWeight: 'bold',
                 border: '1px solid #000',
               }}
          >S</Box>
        ))}
      </Box>
    </Tooltip>
  );
}

function ActionCounter(props: { cost: number }) {
  return (
    <Tooltip title={props.cost === 1 ? '1 Action' : `${props.cost} Actions`} {...toolTipProps}>
      <Box display="flex" alignItems="center">
        {Array.from({ length: props.cost }, (_, index) => (
          <BackHandIcon key={index} fontSize="inherit"/>
        ))}
      </Box>
    </Tooltip>
  );
}

function PassiveMarker() {
  return (
    <Tooltip title="Passive" {...toolTipProps}>
      <Box display="flex" alignItems="center">
        <PanoramaFishEyeIcon fontSize="inherit"/>
      </Box>
    </Tooltip>
  );
}

function ReactionMarker() {
  return (
    <Tooltip title="Reaction" {...toolTipProps}>
      <Box display="flex" alignItems="center">
        <BoltIcon fontSize="inherit"/>
      </Box>
    </Tooltip>
  );
}

function FeatureMoves(props: { moves: AdversaryFeatureMove[] | undefined | null }) {
  if (!props.moves) {
    return null;
  }

  return (
    <Box component="ol" sx={{
      mx: 0,
      my: '0.5em',
      padding: 0,
    }}>
      {props.moves?.map((move, index) => (
        <Box component="li" key={index} sx={{
          ml: '1.2em',
          p: 0,
          '&:not(:last-child)': {
            marginBottom: '0.5em',
          },
        }}>
          <Box fontWeight="bold">
            {move.name}
          </Box>
          <FeatureAttackModifier value={move.attackModifier}/>
          {move.description}
        </Box>
      ))}
    </Box>
  );
}


function FeatureDetails(props: { details?: string[] }) {
  if (!props.details) {
    return null;
  }

  return (
    <Box component="ul" sx={{
      mx: 0,
      my: '0.5em',
      padding: 0,
    }}>
      {props.details?.map((detail, index) => (
        <Box component="li" key={index} sx={{
          ml: '1.2em',
          p: 0,
          '&:not(:last-child)': {
            marginBottom: '0.5em',
          },
        }}>
          {detail}
        </Box>
      ))}
    </Box>
  );
}
