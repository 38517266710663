import * as React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, TextField, TextFieldProps } from "@mui/material";

interface Props extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
}

/**
 * We could not use Unstable_NumberInput because it puts focus on the
 * input when the buttons are clicked, which was annoying on mobile for player view.
 */
export const NumberInput = (props: Props) => {
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 0.5,
    }}>
      <IconButton
        sx={{ order: -1 }}
        size={"small"}
        disabled={props.min !== undefined && props.value <= props.min}
        onClick={() => {
          props.onChange(props.value - 1);
        }}
      ><RemoveIcon fontSize="small" /></IconButton>
      <IconButton
        sx={{ order: 1 }}
        size={"small"}
        disabled={props.max !== undefined && props.value >= props.max}
        onClick={() => {
          props.onChange(props.value + 1);
        }}
      ><AddIcon fontSize="small" /></IconButton>
      <TextField
        size="small"
        {...props}
        value={props.value}
        onChange={(e) => {
          props.onChange(parseInt(e.target.value));
        }}
        type="number"
        inputProps={{
          style: {
            textAlign: "center",
            width: "2em",
          },
          min: props.min,
          max: props.max,
        }}
      />
    </Box>
  );
};
