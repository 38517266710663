import { Box } from '@mui/material';
import { adversaryImage } from '../useAdversaries';
import { useSettings } from "../../game-master/hooks/useSettings";
import { baseBlue, blue } from "../../core/muiTheme";

interface Props {
  adversary: {
    image?: string | null;
    name: string;
  }
}

export function AdversaryImage({ adversary }: Props) {
  const {settings} = useSettings();

  if (settings.disableArtwork) {
    return <Box
      sx={{
        width: '100%',
        height: 0,
        paddingTop: '4.5em',
        position: 'relative',
        // background: `radial-gradient(circle, ${basePurple} 0%, ${baseDarkBlue} 100%)`,
        background: `linear-gradient(200deg, ${baseBlue} 0%, ${blue[900]} 100%);`,
      }}
    >
    </Box>;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 0,
        paddingTop: '61.8%', // golden ratio
        position: 'relative',
        backgroundColor: '#333',
        '& > img': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100.2%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
    >
      <img
        src={adversaryImage(adversary)}
        // alt={adversary.name}
        draggable="false"
      />
    </Box>
  );
}
