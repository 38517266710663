import { UseFormWatch } from "react-hook-form";
import React from "react";
import ErrorBoundary from "../../common/ErrorBoundry";
import { formDataToInput } from "./adversary-form.util";
import { AdversaryCard } from "../../adversaries/AdversaryCard";
import { AdversaryFormData } from "./HbAdversaryFormData.types";


export function AdversaryFormPreview(props: { watch: UseFormWatch<AdversaryFormData> }) {
  const data = props.watch();
  return (
    <ErrorBoundary>
      <AdversaryCard
        adversary={{
          ...formDataToInput(data) as any,
          id: '0',
        }}
        card={{
          quantity: 1,
          instances: [],
          rolls: [],
          upscaling: 0,
        }}
        onDiceRoll={() => {
        }}
        onInstancesUpdate={() => {
        }}
        showDescription
      />
    </ErrorBoundary>
  );
}


