import { BehaviorSubject, Subject } from 'rxjs';
import { useEffect, useState } from 'react';

export function useBehaviorSubject<T>(subject: BehaviorSubject<T>) {
  return useSubject(subject, subject.value);
}

export function useSubject<T>(subject: Subject<T>, initialValue: T): [T, (next: T) => void] {
  const [data, setData] = useState<T>(initialValue);
  useEffect(() => {
    const subscription = subject.subscribe((v) => setData(v));
    return () => subscription.unsubscribe();
  }, [subject]);

  const update = (newValue: T) => subject.next(newValue);

  return [data, update];
}
