import React from "react";
import { Box } from "@mui/material";
import { useListAdversariesForReviewQuery } from "../generated/graphql";
import { AdminAdversaryCatalogGrid } from "./AdminAdversaryCatalogGrid";

export function ReviewPage() {
  return (
    <Box m={1} sx={{
      overflowY: 'auto',
    }}>
      <ReviewAdversariesList />
    </Box>
  );
}


function ReviewAdversariesList() {
  const { data, error } = useListAdversariesForReviewQuery();

  const adversaries = data?.listAdversariesForReview || [];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <AdminAdversaryCatalogGrid adversaries={adversaries} />
  )
}
