import { useState } from "react";
import { convert3DDiceResultToDiceRollEventInput, convertSelectedDiceToDiceNotation } from "../../dice/dice.util";
import { clearDiceAfterTimeout, Dice, resetDiceCleanupTimer } from "../../dice/diceBoxSetup";

import DiceParser from '@3d-dice/dice-parser-interface'

const DP = new DiceParser()

export function useAdversaryDiceRoll() {
  const rollDice = async (dice: string, name: string) => {
    const diceNotations = DP.parseNotation(dice);
    const modifier = getModifier(dice);

    // const diceNotations = convertSelectedDiceToDiceNotation('2d6 + 2');

    resetDiceCleanupTimer();
    const results = await Dice.roll(diceNotations);

    clearDiceAfterTimeout();

    if (results[0].value === undefined) {
      // Something went wrong. This happens sometimes when .hide() is called.
      // Not calling that anymore.
      console.error('Error rolling dice', results);
      return;
    }

    const diceRollEvent = convert3DDiceResultToDiceRollEventInput(
      results,
      modifier,
      name,
    );

    return diceRollEvent;
  };

  return { rollDice };
}


function getModifier(dice: string) {
  const diceRegex = /(\d+)d(\d+)([-+]\d+)?/;
  const match = dice
    .replace(/\s/g, '') //remove spaces
    .match(diceRegex);

  if (!match) {
    return 0;
  }

  return match[3] ? parseInt(match[3], 10) : 0;
}
