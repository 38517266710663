import React from 'react';
import { CardCustomizationDialog } from './CardCustomizationDialog';
import { DeckCard, DeckCardUpdateProps } from '../DeckBuilder/Deck.types';
import { Adversary } from '../../../adversaries/Adversary.type';
import { useDeck } from '../DeckBuilder/useDeck';
import { Environment } from "../../../adversaries/Environment.type";

interface Props {
  adversary: Adversary | Environment;
  card: DeckCard;
  open: boolean;
  onClose: () => void;
}

export function CardEditDialog(props: Props) {
  const { updateCardInDeck } = useDeck();

  const handleSaveCustomization = (c: DeckCardUpdateProps) => {
    props.onClose();
    updateCardInDeck(props.card.id, c);
  };

  return (
    <CardCustomizationDialog
      adversary={props.adversary}
      open={props.open}
      isNew={false}
      quantity={props.card.quantity}
      instances={props.card.instances}
      upscaling={props.card.upscaling}
      onClose={() => props.onClose()}
      onSave={handleSaveCustomization}
    />
  );
}
