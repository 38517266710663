import { Box, CardActionArea } from "@mui/material";
import { AdversaryCardTop } from "../../../../adversaries/Adversary/AdversaryCardTop";
import { TopLeft } from "../../../../adversaries/Adversary/TopLeft";
import { TierBadge } from "../../../../adversaries/Adversary/TeirBadge";
import { AdversaryImage } from "../../../../adversaries/Adversary/AdversaryImage";
import { AdversaryTypeWithDivider } from "../../../../adversaries/Adversary/AdversaryType";
import CardContent from "@mui/material/CardContent";
import { AdversaryName } from "../../../../adversaries/Adversary/AdversaryName";
import { AdversaryDescription } from "../../../../adversaries/Adversary/AdversaryDescription";
import { MotivesAndTactics } from "../../../../adversaries/Adversary/AdversaryMotivesAndTactics";
import React from "react";
import Card from "@mui/material/Card";
import { Adversary } from "../../../../adversaries/Adversary.type";
import { Environment } from "../../../../adversaries/Environment.type";
import { isEnvironment } from "../../../../adversaries/adversary.util";
import {
  AdversaryEnvironmentPotentialAdversaries
} from "../../../../adversaries/Adversary/AdversaryEnvironmentPotentialAdversaries";
import { AdversaryEnvironmentToneAndFeel } from "../../../../adversaries/Adversary/AdversaryEnvironmentToneAndFeel";

interface Props {
  adversary: Adversary | Environment;
  onClick: () => void;
}

export function DeckCatalogGridAdversary(props: Props) {
  const { adversary, onClick } = props;

  return (
    <Card sx={{
      borderRadius: 2,
      height: '100%',
      display: 'flex',
    }} elevation={1}>
      <CardActionArea onClick={onClick} sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        '& > *': {
          width: '100%',
        },
      }}>
        <AdversaryCardTop>
          <TopLeft>
            <TierBadge tier={adversary.tier} />
          </TopLeft>
          <AdversaryImage adversary={adversary} />
          <AdversaryTypeWithDivider type={adversary.type} isEnv={isEnvironment(adversary)}/>
        </AdversaryCardTop>
        <CardContent>
          <AdversaryName name={adversary.name} />
          <AdversaryDescription>{adversary.shortDescription}</AdversaryDescription>
          {isEnvironment(adversary) ? (
            <>
              <AdversaryEnvironmentToneAndFeel adversary={adversary} />
              <AdversaryEnvironmentPotentialAdversaries adversary={adversary} />
            </>
          ) : (
            <MotivesAndTactics adversary={adversary} />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
