import React from 'react';
import { Box } from '@mui/material';
import { useSelectedDie } from "./hooks/useDiceSelection";
import { DiceBlock } from "./DiceRollResultItem";


function SelectedDiceList() {
  const [dice, setDice] = useSelectedDie();

  const removeDie = (index: number) => {
    setDice(dice.filter((_, i) => i !== index));
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      fontSize="1em"
      alignContent="center"
      alignItems="center"
      minHeight="2.2em"
      px={1}
    >
      {dice.map((die, index) => (
        <Box key={index} onClick={() => removeDie(index)} sx={{
          cursor: 'pointer',
        }}>
          <Box className={`dice-shake-${index % 4}`} p={'0.2em'}>
            <DiceBlock
              type={die.type}
              sides={die.sides}
              text={(die.type === 'disadvantage' ? '-' : '') + die.sides}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SelectedDiceList;
