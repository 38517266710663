import { AdversaryInput, AdversaryType, FeatureType } from "../generated/graphql";

export const adversaryExampleData: AdversaryInput = {
  "name": "Adversary name",
  "public": true,
  "shortDescription": "Short description",
  "tags": [],
  "image": "",
  "locations": [],
  "weapon": {
    "name": "Weapon name",
    "damage": "1d6",
    "range": "far"
  },
  "attackModifier": "0",
  "features": [
    {
      "name": "Feature A",
      "type": FeatureType.Action,
      "description": "",
      "summon": [],
      "cost": {
        "actions": 2
      }
    },
    {
      "name": "Feature B",
      "type": FeatureType.Passive,
      "description": "",
      "summon": [],
      "cost": {}
    }
  ],
  "hitPoints": 7,
  "stress": 2,
  "experience": [],
  "motivesAndTactics": [
    'Plunder'
  ],
  "type": AdversaryType.Standard,
  "difficulty": 10,
  "damageThresholds": {
    "minor": 1,
  }
}
