import { Adversary as AdversaryHbOrOfficial } from "./Adversary.type";
import { Environment } from "./Environment.type";

export function isAdversary(a: (AdversaryHbOrOfficial | Environment)): a is AdversaryHbOrOfficial {
  return (a as AdversaryHbOrOfficial).attackModifier !== undefined;
}

export function isEnvironment(a: (AdversaryHbOrOfficial | Environment)): a is Environment {
  return (a as AdversaryHbOrOfficial).attackModifier === undefined;
}
