import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Adversary } from '../../../../adversaries/Adversary.type';
import { AdversaryCardTop } from '../../../../adversaries/Adversary/AdversaryCardTop';
import { TierBadge } from '../../../../adversaries/Adversary/TeirBadge';
import { AdversaryImage } from '../../../../adversaries/Adversary/AdversaryImage';
import { AdversaryTypeWithDivider } from '../../../../adversaries/Adversary/AdversaryType';
import { AdversaryName } from '../../../../adversaries/Adversary/AdversaryName';
import { AdversaryDescription } from '../../../../adversaries/Adversary/AdversaryDescription';
import { MotivesAndTactics } from '../../../../adversaries/Adversary/AdversaryMotivesAndTactics';
import { TopLeft } from '../../../../adversaries/Adversary/TopLeft';
import { CardAddDialog } from '../../CardCustomization/CardAddDialog';
import { DeckCatalogGridAdversary } from "./DeckCatalogGridAdversary";
import { Environment } from "../../../../adversaries/Environment.type"; // Adjust path as necessary

interface AdversaryCardProps {
  adversary: (Adversary | Environment);
}

export function AdversaryCardAdd({ adversary }: AdversaryCardProps) {
  const [customizationOpen, setCustomizationOpen] = useState(false);

  return (
    <>
      <DeckCatalogGridAdversary
        adversary={adversary}
        onClick={() => setCustomizationOpen(true)}
      />
      {customizationOpen && (
        <CardAddDialog
          open={true}
          adversary={adversary}
          onClose={() => setCustomizationOpen(false)}
        />
      )}
    </>
  );
}
