// General handler for graph ql errors

import { ApolloError } from '@apollo/client';
import React from 'react';
import { Alert } from '@mui/material';

interface Props {
  error: ApolloError | undefined,
}

export default function ErrorMessage({ error }: Props) {
  if (!error) {
    return null;
  }

  if (error.networkError?.message === 'Failed to fetch') {
    return (
      <Alert severity="error">
        Unable to reach the server
      </Alert>
    );
  }

  if (error.graphQLErrors?.length === 0) {
    return (
      <Alert severity="error">
        {error.message}
      </Alert>
    );
  }

  return (
    <Alert severity="error">
      {error.graphQLErrors?.length === 0 ? error.message : null}
      {error.graphQLErrors?.map((e, i) => (
        <pre key={i}>{e.message}</pre>
      ))}
    </Alert>
  );
}

