import { useLocalStorage } from 'usehooks-ts';

export interface Settings {
  cardSize: number;
  detailedView: boolean;
  showActionTracker: boolean;
  showFearTracker: boolean;
  showPlayerDiceRolls: boolean;
  background: string;
  useExperimentalFeatures: boolean;
  disableArtwork: boolean;
}

const defaultSettings: Settings = {
  cardSize: 5,
  detailedView: false,
  showActionTracker: false,
  showFearTracker: false,
  showPlayerDiceRolls: false,
  background: 'default',
  useExperimentalFeatures: false,
  disableArtwork: false,
};

export function useSettings() {
  const [settings, setSettings] = useLocalStorage('settings', {});

  return {
    settings: {
      ...defaultSettings,
      ...settings,
    } as Settings,
    updateSettings: (newSettings: Partial<Settings>) => {
      setSettings({
        ...settings,
        ...newSettings,
      });
    },
  };
}
