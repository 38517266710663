import { TextField } from "@mui/material";
import React from "react";
import { useEncounters } from "../../hooks/useEncounters";
import { useDebounceCallback } from "usehooks-ts";


export function EncounterNameInput() {
  const { currentEncounter, renameEncounter } = useEncounters()

  const debounced = useDebounceCallback((id, v) => {
    renameEncounter(id, v)
  }, 100)

  if (!currentEncounter) {
    return null;
  }

  return (
    <TextField
      label="Encounter Name"
      variant="outlined"
      defaultValue={currentEncounter.name || ''}
      onChange={(e) => debounced(currentEncounter.id, e.target.value)}
      fullWidth
      size="small"
    />
  );
}
