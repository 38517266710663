import React from "react";
import { Box } from "@mui/material";


export function DiceRollPlayerName(props: { playerName: string }) {
  return (
    <Box sx={{
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      padding: "0 0.2em 0 0.6em",
      lineHeight: '1.6em',
      fontSize: "0.6em",
    }}>
      {/* diagonal corner to the right */}
      <Box sx={{
        position: "absolute",
        top: 0,
        left: '100%',
        width: 0,
        height: '100%',
        borderRight: "1.6em solid transparent",
        borderTop: "1.6em solid rgba(0, 0, 0, 0.5)",
      }}/>
      {props.playerName}
    </Box>
  );
}
