import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DiscordButton2 } from './InfoText';
import React from "react";

export function WelcomeText() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100vw">
      <Card sx={{
        textAlign: 'center',
        maxWidth: '800px',
        mx: 1,
        background: 'linear-gradient(160deg, #2878be, #4e30ab)',
        color: 'white',
      }} elevation={3}>
        <CardContent>
          <Typography variant="h2" sx={{ fontSize: '1em' }} mb={2}>
            <Box component="span" sx={{ display: 'block', fontSize: '2.5em' }}>Smiley day to ya!</Box>
          </Typography>
          <Typography variant="h1" sx={{ fontSize: '1em' }} mb={4}>
            <Box component="span" sx={{ display: 'block', fontSize: '1.25em', fontWeight: "bold" }}>Welcome to the </Box>
            <Box component="span" sx={{ display: 'block', fontSize: '3em', fontWeight: "bold" }}>Daggerheart GM Screen!</Box>
          </Typography>
          <DiscordButton2 />
          <Box mt={2}>Click <strong>"Build encounter"</strong> to start building your adversary deck.</Box>
        </CardContent>
      </Card>
    </Box>
  );
}
