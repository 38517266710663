import React, { useState } from 'react';
import { Box, TextField, IconButton, Snackbar } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';

interface LinkCopyProps {
  link: string;
}

const LinkCopy: React.FC<LinkCopyProps> = ({ link }) => {
  const [open, setOpen] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setOpen(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <TextField
        value={link}
        variant="outlined"
        size="small"
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />
      <IconButton color="primary" onClick={handleCopy}>
        <FileCopyIcon />
      </IconButton>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LinkCopy;
