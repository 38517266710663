import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type HorizontalScrollProps = {
  children: React.ReactNode;
};

const buttonStyle = {
  color: 'white',
  background: 'rgba(0, 0, 0, 0.65)',
  zIndex: 3,
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.85)',
  },
};

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        const { scrollWidth, clientWidth } = scrollRef.current;
        setShowScrollButtons(scrollWidth > clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [children]); // Depend on children as it might change

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = current.firstChild ? (current.firstChild as HTMLElement).offsetWidth : 0;
      current.scrollTo({
        left: current.scrollLeft + (direction === 'right' ? scrollAmount : -scrollAmount),
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box sx={{
      position: 'relative',
      height: '100%',
      width: '100%',
    }}>
      {showScrollButtons && (
        <Box sx={{
          position: 'absolute',
          left: '15px',
          bottom: '15px',
        }}>
          <IconButton onClick={() => handleScroll('left')} aria-label="Scroll left" sx={buttonStyle}>
            <ArrowBackIcon sx={{ color: 'white' }}/>
          </IconButton>
        </Box>
      )}
      <Box
        ref={scrollRef}
        sx={{
          overflowY: 'hidden',
          overflowX: 'scroll', // Each item is also scrollable
          display: 'flex',
          pr: '1.5em',
          height: '100%',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Box>
      {showScrollButtons && (
        <Box sx={{
          position: 'absolute',
          right: '15px',
          bottom: '15px',
        }}>
          <IconButton onClick={() => handleScroll('right')} aria-label="Scroll right" sx={buttonStyle}>
            <ArrowForwardIcon sx={{ color: 'white' }}/>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default HorizontalScroll;
