import { Adversary } from "../../../adversaries/Adversary.type";
import { Environment } from "../../../adversaries/Environment.type";

export function adversarySearchScore(ad: Adversary | Environment, search: string) {
  if (!search) {
    return 1;
  }

  if (ad.name.toLowerCase().includes(search.toLowerCase())) {
    return 10;
  }

  if (ad.shortDescription.toLowerCase().includes(search.toLowerCase())) {
    return 9;
  }

  if (ad.tags.some((tag) => tag.toLowerCase().includes(search.toLowerCase()))) {
    return 8;
  }

  return 0;
}
