import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import reportWebVitals from './reportWebVitals';
import { theme } from './core/muiTheme';
import { Analytics } from '@vercel/analytics/react';
import { router } from "./Router";
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./core/apolloClient";
import { AblyProvider } from "ably/react";
import { ablyClient } from "./core/ablyClient";
import FastClick from 'fastclick';

(FastClick as any).attach(document.body);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Analytics/>
      <CssBaseline/>
      <ApolloProvider client={apolloClient}>
        <AblyProvider client={ablyClient}>
          <RouterProvider router={router}/>
        </AblyProvider>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
