import { useLocalStorage } from "usehooks-ts";
import shortUuid from "short-uuid";

const defaultGameId = shortUuid.generate();

export function useGmGameId() {
  const [value] = useLocalStorage<string | null>('gmGameId2', null, {
    deserializer: (v) => v,
  });

  if (value) {
    return value;
  }

  localStorage.setItem('gmGameId2', defaultGameId);

  return defaultGameId;
}
