// adversaryTypeEnum
export const adversaryTypeEnum = [
  "Standard",
  "Leader",
  "Minion",
  "Ranged",
  "Skulk",
  "Social",
  "Solo",
  "Support",
  "Bruiser",
  "Horde"
];

// adversaryLocationEnum
export const adversaryLocationEnum = [
  "Aquatic",
  "Arctic",
  "Desert",
  "Forest",
  "Mountain",
  "Swamp",
  "Urban",
  "Cave"
];

// featureTypeEnum
export const featureTypeEnum = [
  "Action",
  "Reaction",
  "Passive"
];

export const attackRangeEnum = [
  'Melee',
  'Very Close',
  'Close',
  'Far',
  'Very Far',
];

export const tagsEnum = [
  "Beasts",
  "Constructs",
  "Criminals",
  "Dragons",
  "Elementals",
  "Fiends",
  "Flying",
  "Giants",
  "Humanoids",
  "Insects",
  "Mercenaries",
  "Monstrosities",
  "Sailors",
  "Undead"
];
