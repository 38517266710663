import { AdversaryInput, AdversaryType } from "../../../generated/graphql";

export const defaultAdversaryFormData: AdversaryInput = {
  name: '',
  public: false,
  shortDescription: '',
  tier: 0,
  tags: [],
  image: '',
  locations: [],
  weapon: {
    name: 'Claws',
    damage: '1d6',
    range: 'Melee',
  },
  attackModifier: '0',
  features: [],
  hitPoints: 5,
  stress: 2,
  experience: [],
  damageThresholds: {
    minor: 1,
    major: 5,
    severe: 10,
  },
  motivesAndTactics: [],
  type: AdversaryType.Standard,
  difficulty: 10,
}
