import { AdversaryDetailsFragment } from "../generated/graphql";
import { Box } from "@mui/material";
import { AdversaryCard } from "../adversaries/AdversaryCard";
import { DeckCatalogGrid } from "../game-master/encounter/DeckBuilder/DeckCatalog/DeckCatalogGrid";
import React from "react";


interface Props {
  adversaries: AdversaryDetailsFragment[];
}

export function AdminAdversaryCatalogGrid(props: Props) {
  return (
    <>
      Total : {props.adversaries.length}
      <DeckCatalogGrid>
        {props.adversaries.map((adversary) => (
          <Box key={adversary.name + adversary.id}>
            <AdversaryCard
              showDescription={true}
              adversary={adversary}
              elevation={1}
              onInstancesUpdate={() => {
              }}
              onDiceRoll={() => {
              }}
              card={{
                upscaling: 0,
                quantity: 0,
                instances: [],
              }}
            />
            username: {adversary.username} <br />
            imageCredit: {adversary.imageCredit}
          </Box>
        ))}
      </DeckCatalogGrid>
    </>
  )
}