import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import { Adversary } from './Adversary.type';
import { AdversaryImage } from './Adversary/AdversaryImage';
import { AdversaryTypeWithDivider } from './Adversary/AdversaryType';
import { AdversaryName } from './Adversary/AdversaryName';
import { TierBadge } from './Adversary/TeirBadge';
import { AdversaryCardTop } from './Adversary/AdversaryCardTop';
import { TwoColumn } from './Adversary/TwoColumn';
import { AdversaryAttack } from './Adversary/AdversaryAttack';
import { AdversaryDefence } from './Adversary/AdversaryDefence';
import { AdversaryFeatures } from './Adversary/AdversaryFeatures';
import { AdversaryExperience } from './Adversary/AdversaryExperience';
import { CardDivider } from './Adversary/CardDivider';
import { MotivesAndTactics } from './Adversary/AdversaryMotivesAndTactics';
import { TopLeft } from './Adversary/TopLeft';
import { CardInstance, DeckCard } from '../game-master/encounter/DeckBuilder/Deck.types';
import { UpscaleBadge } from './Adversary/UpscaleBadge';
import { InstanceInfo } from "./InstanceInfo";
import React from "react";
import { AdversaryDiceRolls } from "./AdversaryDiceRolls";
import { AdversaryDescription } from "./Adversary/AdversaryDescription";
import { Environment } from "./Environment.type";
import { isEnvironment } from "./adversary.util";
import { AdversaryDifficulty } from "./Adversary/AdversaryDifficulty";

// Define the type for the props to ensure type safety
interface Props {
  adversary?: Adversary | Environment;
  elevation?: number;
  id?: number;
  card?: Pick<DeckCard, 'upscaling' | 'quantity' | 'instances' | 'rolls'>;
  onInstancesUpdate: (instance: CardInstance[]) => void;
  onDiceRoll?: (roll: string) => void;
  showDescription?: boolean;
}

export function AdversaryCard(props: Props) {
  const {
    adversary,
    id,
    elevation,
    card,
    onInstancesUpdate,
    onDiceRoll,
  } = props;
  const upscaling = card?.upscaling ?? 0;

  if (!adversary) {
    return (
      <Card sx={{ borderRadius: 2 }} elevation={elevation ?? 1}>
        <CardContent>Adversary not found</CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ borderRadius: 2 }} elevation={elevation ?? 1}>
      <AdversaryCardTop>
        <TopLeft>
          <TierBadge tier={adversary.tier} />
          <UpscaleBadge upscale={card?.upscaling} />
        </TopLeft>
        <AdversaryImage adversary={adversary} />
        <AdversaryTypeWithDivider type={adversary.type} isEnv={isEnvironment(adversary)} />
        <AdversaryDiceRolls rolls={card?.rolls || []} />
      </AdversaryCardTop>
      <CardContent>
        <AdversaryName name={adversary.name} instances={card?.instances} />
        {props.showDescription && (
          <AdversaryDescription>{adversary.shortDescription}</AdversaryDescription>
        )}
        {!isEnvironment(adversary) ? (
          <AdversaryCardContent
            adversary={adversary}
            upscaling={upscaling}
            card={card}
            onInstancesUpdate={onInstancesUpdate}
            onDiceRoll={onDiceRoll}
          />
        ) : (
          <EnvironmentCardContent
            adversary={adversary}
            upscaling={upscaling}
            onDiceRoll={onDiceRoll}
          />
        )}
      </CardContent>
    </Card>
  );
}

function AdversaryCardContent(props: {
  onDiceRoll: ((roll: string) => void) | undefined,
  onInstancesUpdate: (instance: CardInstance[]) => void,
  upscaling: number,
  adversary: (Adversary & { attackModifier: string }) | Adversary,
  card: Pick<DeckCard, "upscaling" | "quantity" | "instances" | "rolls"> | undefined
}) {
  const {
    onDiceRoll,
    onInstancesUpdate,
    upscaling,
    adversary,
    card,
  } = props;

  return (
    <>
      <AdversaryAttack
        adversary={adversary}
        upscaling={upscaling}
        onDiceRoll={onDiceRoll}
      />
      <TwoColumn>
        <AdversaryExperience experience={adversary.experience} />
        <MotivesAndTactics adversary={adversary} />
      </TwoColumn>

      {(adversary.features && adversary.features.length > 0) && (
        <>
          <CardDivider fontSize="0.9em" m={2}>Features</CardDivider>
          <AdversaryFeatures
            features={adversary.features}
            upscaling={upscaling}
            onDiceRoll={onDiceRoll}
          />
        </>
      )}
      <CardDivider fontSize="0.9em" m={2}>HP &amp; Stress</CardDivider>
      <AdversaryDefence adversary={adversary} upscaling={upscaling} />
      {card?.instances?.map((instance, i) => (
        <Box key={i} mt={1}>
          <InstanceInfo
            adversary={adversary}
            instance={instance}
            index={i}
            showName={card.instances.length > 1}
            onUpdate={(newInstance) => {
              const newInstances = [...card.instances];
              newInstances[i] = newInstance;
              onInstancesUpdate && onInstancesUpdate(newInstances);
            }}
            onRemove={() => {
              const newInstances = [...card.instances];
              newInstances.splice(i, 1);
              onInstancesUpdate && onInstancesUpdate(newInstances);
            }}
          />
        </Box>
      ))}
    </>
  );
}


function EnvironmentCardContent(props: {
  onDiceRoll: ((roll: string) => void) | undefined,
  upscaling: number,
  adversary: Environment,
}) {
  const {
    onDiceRoll,
    upscaling,
    adversary,
  } = props;

  return (
    <>
      {adversary.difficulty && (
        <AdversaryDifficulty baseTeir={adversary.tier || 1} difficulty={adversary.difficulty} upscaling={upscaling} />
      )}
      {(adversary.features && adversary.features.length > 0) && (
        <>
          <CardDivider fontSize="0.9em" m={2}>Features</CardDivider>
          <AdversaryFeatures
            features={adversary.features}
            upscaling={upscaling}
            onDiceRoll={onDiceRoll}
          />
        </>
      )}
    </>
  );
}
