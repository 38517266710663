import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Adversary = {
  __typename?: 'Adversary';
  attackModifier: Scalars['String']['output'];
  damageThresholds: DamageThresholds;
  difficulty: Scalars['Int']['output'];
  experience: Array<Scalars['String']['output']>;
  features: Array<AdversaryFeature>;
  hitPoints: Scalars['Int']['output'];
  hordeUnitsPerHp?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imageCredit?: Maybe<Scalars['String']['output']>;
  locations: Array<Scalars['String']['output']>;
  motivesAndTactics: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  public?: Maybe<Scalars['Boolean']['output']>;
  shortDescription: Scalars['String']['output'];
  sources: Array<Scalars['String']['output']>;
  stress: Scalars['Int']['output'];
  tags: Array<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
  type: AdversaryType;
  username: Scalars['String']['output'];
  weapon: Weapon;
};

export type AdversaryFeature = {
  __typename?: 'AdversaryFeature';
  attackModifier?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<AdversaryFeatureCost>;
  countdown?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  flavourText?: Maybe<Scalars['String']['output']>;
  /** Only used by one base adversary */
  moves?: Maybe<Array<AdversaryFeatureMove>>;
  name: Scalars['String']['output'];
  summon?: Maybe<Array<AdversaryFeatureSummon>>;
  type: FeatureType;
  uses?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type AdversaryFeatureCost = {
  __typename?: 'AdversaryFeatureCost';
  actions?: Maybe<Scalars['Int']['output']>;
  fear?: Maybe<Scalars['Int']['output']>;
  hitPoints?: Maybe<Scalars['Int']['output']>;
  stress?: Maybe<Scalars['Int']['output']>;
};

/** Only used by one base adversary */
export type AdversaryFeatureMove = {
  __typename?: 'AdversaryFeatureMove';
  attackModifier?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdversaryFeatureSummon = {
  __typename?: 'AdversaryFeatureSummon';
  adversaryName: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
};

export type AdversaryInput = {
  attackModifier: Scalars['String']['input'];
  damageThresholds: DamageThresholdsInput;
  difficulty: Scalars['Int']['input'];
  experience?: InputMaybe<Array<Scalars['String']['input']>>;
  features: Array<FeatureInput>;
  hitPoints: Scalars['Int']['input'];
  hordeUnitsPerHp?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  imageCredit?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  motivesAndTactics: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
  shortDescription: Scalars['String']['input'];
  stress: Scalars['Int']['input'];
  tags: Array<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  type: AdversaryType;
  weapon: WeaponInput;
};

export enum AdversaryType {
  Bruiser = 'Bruiser',
  Horde = 'Horde',
  Leader = 'Leader',
  Minion = 'Minion',
  Ranged = 'Ranged',
  Skulk = 'Skulk',
  Social = 'Social',
  Solo = 'Solo',
  Standard = 'Standard',
  Support = 'Support'
}

export type CostInput = {
  actions?: InputMaybe<Scalars['Int']['input']>;
  fear?: InputMaybe<Scalars['Int']['input']>;
  hitPoints?: InputMaybe<Scalars['Int']['input']>;
  stress?: InputMaybe<Scalars['Int']['input']>;
};

export type DamageThresholds = {
  __typename?: 'DamageThresholds';
  major?: Maybe<Scalars['Int']['output']>;
  minor: Scalars['Int']['output'];
  severe?: Maybe<Scalars['Int']['output']>;
};

export type DamageThresholdsInput = {
  major?: InputMaybe<Scalars['Int']['input']>;
  minor: Scalars['Int']['input'];
  severe?: InputMaybe<Scalars['Int']['input']>;
};

export type DiceRoll = {
  __typename?: 'DiceRoll';
  sides: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type DiceRollEventInput = {
  playerId: Scalars['ID']['input'];
  result: DiceRollResultInput;
};

export type DiceRollEventPayload = {
  __typename?: 'DiceRollEventPayload';
  diceRollResult: DiceRollResult;
  playerId: Scalars['ID']['output'];
};

export type DiceRollInput = {
  sides: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type DiceRollResult = {
  __typename?: 'DiceRollResult';
  diceRolls: Array<DiceRoll>;
  isCrit?: Maybe<Scalars['Boolean']['output']>;
  modifier: Scalars['Int']['output'];
  playerName: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  withHope?: Maybe<Scalars['Boolean']['output']>;
};

export type DiceRollResultInput = {
  diceRolls: Array<DiceRollInput>;
  isCrit?: InputMaybe<Scalars['Boolean']['input']>;
  modifier: Scalars['Int']['input'];
  playerName: Scalars['String']['input'];
  total: Scalars['Int']['input'];
  withHope?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeatureInput = {
  attackModifier?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<CostInput>;
  description: Scalars['String']['input'];
  flavourText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  summon?: InputMaybe<Array<SummonInput>>;
  type: FeatureType;
  uses?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export enum FeatureType {
  Action = 'Action',
  Passive = 'Passive',
  Reaction = 'Reaction'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAdversary: Adversary;
  deleteAdversary: Scalars['Boolean']['output'];
  registerDiceRoll: Scalars['Boolean']['output'];
  updateAdversary: Adversary;
};


export type MutationCreateAdversaryArgs = {
  input: AdversaryInput;
};


export type MutationDeleteAdversaryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRegisterDiceRollArgs = {
  diceRollEvent: DiceRollEventInput;
  sessionId: Scalars['ID']['input'];
};


export type MutationUpdateAdversaryArgs = {
  id: Scalars['ID']['input'];
  input: AdversaryInput;
};

export type Query = {
  __typename?: 'Query';
  diceRolls: Array<DiceRoll>;
  getAdversary?: Maybe<Adversary>;
  listAdversaries?: Maybe<Array<Adversary>>;
  listAdversariesForReview?: Maybe<Array<Adversary>>;
  listMyAdversaries?: Maybe<Array<Adversary>>;
  listPrivateAdversaries?: Maybe<Array<Adversary>>;
};


export type QueryGetAdversaryArgs = {
  id: Scalars['ID']['input'];
};

export type SummonInput = {
  adversaryName: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
};

export type Weapon = {
  __typename?: 'Weapon';
  damage: Scalars['String']['output'];
  name: Scalars['String']['output'];
  range: Scalars['String']['output'];
};

export type WeaponInput = {
  damage: Scalars['String']['input'];
  name: Scalars['String']['input'];
  range: Scalars['String']['input'];
};

export type ListAdversariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdversariesQuery = { __typename?: 'Query', listAdversaries?: Array<{ __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> }> | null };

export type ListMyAdversariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListMyAdversariesQuery = { __typename?: 'Query', listMyAdversaries?: Array<{ __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> }> | null };

export type ListAdversariesForReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdversariesForReviewQuery = { __typename?: 'Query', listAdversariesForReview?: Array<{ __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> }> | null };

export type ListPrivateAdversariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPrivateAdversariesQuery = { __typename?: 'Query', listPrivateAdversaries?: Array<{ __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> }> | null };

export type AdversaryDetailsFragment = { __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> };

export type CreateAdversaryMutationVariables = Exact<{
  input: AdversaryInput;
}>;


export type CreateAdversaryMutation = { __typename?: 'Mutation', createAdversary: { __typename?: 'Adversary', id: string } };

export type DeleteAdversaryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAdversaryMutation = { __typename?: 'Mutation', deleteAdversary: boolean };

export type UpdateAdversaryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: AdversaryInput;
}>;


export type UpdateAdversaryMutation = { __typename?: 'Mutation', updateAdversary: { __typename?: 'Adversary', id: string, public?: boolean | null, name: string, username: string, tags: Array<string>, locations: Array<string>, image?: string | null, imageCredit?: string | null, shortDescription: string, motivesAndTactics: Array<string>, tier?: number | null, type: AdversaryType, hordeUnitsPerHp?: number | null, difficulty: number, attackModifier: string, hitPoints: number, stress: number, experience: Array<string>, sources: Array<string>, weapon: { __typename?: 'Weapon', name: string, range: string, damage: string }, damageThresholds: { __typename?: 'DamageThresholds', minor: number, major?: number | null, severe?: number | null }, features: Array<{ __typename?: 'AdversaryFeature', name: string, type: FeatureType, description: string, flavourText?: string | null, value?: number | null, attackModifier?: string | null, uses?: number | null, cost?: { __typename?: 'AdversaryFeatureCost', actions?: number | null, fear?: number | null, stress?: number | null, hitPoints?: number | null } | null }> } };

export type RegisterDiceRollMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  diceRollEvent: DiceRollEventInput;
}>;


export type RegisterDiceRollMutation = { __typename?: 'Mutation', registerDiceRoll: boolean };

export const AdversaryDetailsFragmentDoc = gql`
    fragment AdversaryDetails on Adversary {
  id
  public
  name
  username
  tags
  locations
  image
  imageCredit
  shortDescription
  motivesAndTactics
  tier
  type
  hordeUnitsPerHp
  difficulty
  attackModifier
  weapon {
    name
    range
    damage
  }
  damageThresholds {
    minor
    major
    severe
  }
  hitPoints
  stress
  experience
  features {
    name
    type
    description
    flavourText
    value
    attackModifier
    uses
    cost {
      actions
      fear
      stress
      hitPoints
    }
  }
  sources
}
    `;
export const ListAdversariesDocument = gql`
    query ListAdversaries {
  listAdversaries {
    ...AdversaryDetails
  }
}
    ${AdversaryDetailsFragmentDoc}`;

/**
 * __useListAdversariesQuery__
 *
 * To run a query within a React component, call `useListAdversariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdversariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdversariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdversariesQuery(baseOptions?: Apollo.QueryHookOptions<ListAdversariesQuery, ListAdversariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdversariesQuery, ListAdversariesQueryVariables>(ListAdversariesDocument, options);
      }
export function useListAdversariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdversariesQuery, ListAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdversariesQuery, ListAdversariesQueryVariables>(ListAdversariesDocument, options);
        }
export function useListAdversariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAdversariesQuery, ListAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAdversariesQuery, ListAdversariesQueryVariables>(ListAdversariesDocument, options);
        }
export type ListAdversariesQueryHookResult = ReturnType<typeof useListAdversariesQuery>;
export type ListAdversariesLazyQueryHookResult = ReturnType<typeof useListAdversariesLazyQuery>;
export type ListAdversariesSuspenseQueryHookResult = ReturnType<typeof useListAdversariesSuspenseQuery>;
export type ListAdversariesQueryResult = Apollo.QueryResult<ListAdversariesQuery, ListAdversariesQueryVariables>;
export const ListMyAdversariesDocument = gql`
    query ListMyAdversaries {
  listMyAdversaries {
    ...AdversaryDetails
  }
}
    ${AdversaryDetailsFragmentDoc}`;

/**
 * __useListMyAdversariesQuery__
 *
 * To run a query within a React component, call `useListMyAdversariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyAdversariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyAdversariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListMyAdversariesQuery(baseOptions?: Apollo.QueryHookOptions<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>(ListMyAdversariesDocument, options);
      }
export function useListMyAdversariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>(ListMyAdversariesDocument, options);
        }
export function useListMyAdversariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>(ListMyAdversariesDocument, options);
        }
export type ListMyAdversariesQueryHookResult = ReturnType<typeof useListMyAdversariesQuery>;
export type ListMyAdversariesLazyQueryHookResult = ReturnType<typeof useListMyAdversariesLazyQuery>;
export type ListMyAdversariesSuspenseQueryHookResult = ReturnType<typeof useListMyAdversariesSuspenseQuery>;
export type ListMyAdversariesQueryResult = Apollo.QueryResult<ListMyAdversariesQuery, ListMyAdversariesQueryVariables>;
export const ListAdversariesForReviewDocument = gql`
    query ListAdversariesForReview {
  listAdversariesForReview {
    ...AdversaryDetails
  }
}
    ${AdversaryDetailsFragmentDoc}`;

/**
 * __useListAdversariesForReviewQuery__
 *
 * To run a query within a React component, call `useListAdversariesForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdversariesForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdversariesForReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdversariesForReviewQuery(baseOptions?: Apollo.QueryHookOptions<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>(ListAdversariesForReviewDocument, options);
      }
export function useListAdversariesForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>(ListAdversariesForReviewDocument, options);
        }
export function useListAdversariesForReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>(ListAdversariesForReviewDocument, options);
        }
export type ListAdversariesForReviewQueryHookResult = ReturnType<typeof useListAdversariesForReviewQuery>;
export type ListAdversariesForReviewLazyQueryHookResult = ReturnType<typeof useListAdversariesForReviewLazyQuery>;
export type ListAdversariesForReviewSuspenseQueryHookResult = ReturnType<typeof useListAdversariesForReviewSuspenseQuery>;
export type ListAdversariesForReviewQueryResult = Apollo.QueryResult<ListAdversariesForReviewQuery, ListAdversariesForReviewQueryVariables>;
export const ListPrivateAdversariesDocument = gql`
    query ListPrivateAdversaries {
  listPrivateAdversaries {
    ...AdversaryDetails
  }
}
    ${AdversaryDetailsFragmentDoc}`;

/**
 * __useListPrivateAdversariesQuery__
 *
 * To run a query within a React component, call `useListPrivateAdversariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrivateAdversariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrivateAdversariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPrivateAdversariesQuery(baseOptions?: Apollo.QueryHookOptions<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>(ListPrivateAdversariesDocument, options);
      }
export function useListPrivateAdversariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>(ListPrivateAdversariesDocument, options);
        }
export function useListPrivateAdversariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>(ListPrivateAdversariesDocument, options);
        }
export type ListPrivateAdversariesQueryHookResult = ReturnType<typeof useListPrivateAdversariesQuery>;
export type ListPrivateAdversariesLazyQueryHookResult = ReturnType<typeof useListPrivateAdversariesLazyQuery>;
export type ListPrivateAdversariesSuspenseQueryHookResult = ReturnType<typeof useListPrivateAdversariesSuspenseQuery>;
export type ListPrivateAdversariesQueryResult = Apollo.QueryResult<ListPrivateAdversariesQuery, ListPrivateAdversariesQueryVariables>;
export const CreateAdversaryDocument = gql`
    mutation CreateAdversary($input: AdversaryInput!) {
  createAdversary(input: $input) {
    id
  }
}
    `;
export type CreateAdversaryMutationFn = Apollo.MutationFunction<CreateAdversaryMutation, CreateAdversaryMutationVariables>;

/**
 * __useCreateAdversaryMutation__
 *
 * To run a mutation, you first call `useCreateAdversaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdversaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdversaryMutation, { data, loading, error }] = useCreateAdversaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdversaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdversaryMutation, CreateAdversaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdversaryMutation, CreateAdversaryMutationVariables>(CreateAdversaryDocument, options);
      }
export type CreateAdversaryMutationHookResult = ReturnType<typeof useCreateAdversaryMutation>;
export type CreateAdversaryMutationResult = Apollo.MutationResult<CreateAdversaryMutation>;
export type CreateAdversaryMutationOptions = Apollo.BaseMutationOptions<CreateAdversaryMutation, CreateAdversaryMutationVariables>;
export const DeleteAdversaryDocument = gql`
    mutation DeleteAdversary($id: ID!) {
  deleteAdversary(id: $id)
}
    `;
export type DeleteAdversaryMutationFn = Apollo.MutationFunction<DeleteAdversaryMutation, DeleteAdversaryMutationVariables>;

/**
 * __useDeleteAdversaryMutation__
 *
 * To run a mutation, you first call `useDeleteAdversaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdversaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdversaryMutation, { data, loading, error }] = useDeleteAdversaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdversaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdversaryMutation, DeleteAdversaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdversaryMutation, DeleteAdversaryMutationVariables>(DeleteAdversaryDocument, options);
      }
export type DeleteAdversaryMutationHookResult = ReturnType<typeof useDeleteAdversaryMutation>;
export type DeleteAdversaryMutationResult = Apollo.MutationResult<DeleteAdversaryMutation>;
export type DeleteAdversaryMutationOptions = Apollo.BaseMutationOptions<DeleteAdversaryMutation, DeleteAdversaryMutationVariables>;
export const UpdateAdversaryDocument = gql`
    mutation UpdateAdversary($id: ID!, $input: AdversaryInput!) {
  updateAdversary(id: $id, input: $input) {
    ...AdversaryDetails
  }
}
    ${AdversaryDetailsFragmentDoc}`;
export type UpdateAdversaryMutationFn = Apollo.MutationFunction<UpdateAdversaryMutation, UpdateAdversaryMutationVariables>;

/**
 * __useUpdateAdversaryMutation__
 *
 * To run a mutation, you first call `useUpdateAdversaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdversaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdversaryMutation, { data, loading, error }] = useUpdateAdversaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdversaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdversaryMutation, UpdateAdversaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdversaryMutation, UpdateAdversaryMutationVariables>(UpdateAdversaryDocument, options);
      }
export type UpdateAdversaryMutationHookResult = ReturnType<typeof useUpdateAdversaryMutation>;
export type UpdateAdversaryMutationResult = Apollo.MutationResult<UpdateAdversaryMutation>;
export type UpdateAdversaryMutationOptions = Apollo.BaseMutationOptions<UpdateAdversaryMutation, UpdateAdversaryMutationVariables>;
export const RegisterDiceRollDocument = gql`
    mutation RegisterDiceRoll($sessionId: ID!, $diceRollEvent: DiceRollEventInput!) {
  registerDiceRoll(sessionId: $sessionId, diceRollEvent: $diceRollEvent)
}
    `;
export type RegisterDiceRollMutationFn = Apollo.MutationFunction<RegisterDiceRollMutation, RegisterDiceRollMutationVariables>;

/**
 * __useRegisterDiceRollMutation__
 *
 * To run a mutation, you first call `useRegisterDiceRollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDiceRollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDiceRollMutation, { data, loading, error }] = useRegisterDiceRollMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      diceRollEvent: // value for 'diceRollEvent'
 *   },
 * });
 */
export function useRegisterDiceRollMutation(baseOptions?: Apollo.MutationHookOptions<RegisterDiceRollMutation, RegisterDiceRollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterDiceRollMutation, RegisterDiceRollMutationVariables>(RegisterDiceRollDocument, options);
      }
export type RegisterDiceRollMutationHookResult = ReturnType<typeof useRegisterDiceRollMutation>;
export type RegisterDiceRollMutationResult = Apollo.MutationResult<RegisterDiceRollMutation>;
export type RegisterDiceRollMutationOptions = Apollo.BaseMutationOptions<RegisterDiceRollMutation, RegisterDiceRollMutationVariables>;