// Example usage
const sidesList = [4, 6, 8, 10, 12, 20];
const maxCount = 8;
const diceList = generateAllDiceCombinations(sidesList, maxCount);

type Dice = {
  count: number;
  sides: number;
  average: number;
};

function generateAllDiceCombinations(sidesList: number[], maxCount: number): Dice[] {
  const allDice: Dice[] = [];

  for (const sides of sidesList) {
    for (let count = 1; count <= maxCount; count++) {
      if (count > sides / 2) {
        continue; // Never use more dice than sides
      }

      allDice.push({
        count,
        sides,
        average: count * (sides + 1) / 2,
      });
    }
  }

  return allDice.sort((a, b) => a.average - b.average);
}

function findClosestDice(average: number, minCount = 1): Dice {
  // filter out dice with count less than minCount
  // We can't do this inside the loop because
  // we need all dice to be valid when checking the next dice
  // if it's average is too high and that dice need to be valid too.
  let dl = diceList.filter(dice => dice.count >= minCount);

  if (dl.length === 0) {
    dl = diceList; // Just in case minCount was higher than the max count
  }

  // loop all dice combinations
  for (let i = 0; i < dl.length - 1; i++) {
    // If the next dice average is higher than the target average
    if (dl[i + 1].average > average) {
      return dl[i];
    }
  }

  // return last dice
  return dl[dl.length - 1];
}

/*
function scaleDamage(diceNotation: string, tier: number, diceList: Dice[]): string {
  const parts = diceNotation.split('d');
  const dice = {
    count: parseInt(parts[0], 10),
    sides: parseInt(parts[1], 10)
  };
  const originalAverage = calculateAverage(dice);
  const scaledAverage = originalAverage * Math.pow(2, tier);

  const closestDice = findClosestDice(scaledAverage, diceList);
  return `${closestDice.count}d${closestDice.sides}`;
}

function calculateAverage(dice: { count: number, sides: number }): number {
  return dice.count * (dice.sides + 1) / 2;
}
*/

/**
 * Dice notation could be "1d6" or "1d6+6" or just "6"
 */
export function scaleDamage(diceNotation: string, tier: number): string {
  if (tier === 0) {
    return diceNotation; // No scaling needed
  }

  const [dicePart, bonusPart] = diceNotation.split('+').map((s) => s.trim());
  const bonus = parseInt(bonusPart, 10) || 0;

  const multiplier = 1.5 ** tier;

  if (!dicePart.includes('d')) {
    return `${Math.floor(parseInt(dicePart, 10) * multiplier) + bonus}`;
  }

  const [count, sides] = dicePart.split('d').map(Number);
  const originalAverage = count * (sides + 1) / 2;
  const scaledAverage = originalAverage * multiplier;
  const scaledBonus = Math.floor(bonus * multiplier);

  // When scaling up, we don't want go below the original dice count
  // because it felt weird to scale up from 2d6 to 1d20 and that
  // and it could add a lot of randomness to the damage
  const minCount = tier >= 0 ? count : 1;

  const closestDice = findClosestDice(scaledAverage, minCount);

  return `${closestDice.count}d${closestDice.sides}${scaledBonus ? ` + ${scaledBonus}` : ''}`;
}

// console.log(diceList)
//
// console.log('1d6+6', -2, scaleDamage('1d6+6', -2));
// console.log('1d6+6', -1, scaleDamage('1d6+6', -1));
// console.log('1d6+6', 0, scaleDamage('1d6+6', 0));
// console.log('1d6+6', 1, scaleDamage('1d6+6', 1));
// console.log('1d6+6', 2, scaleDamage('1d6+6', 2));
// console.log('1d6+6', 3, scaleDamage('1d6+6', 3));
// console.log('1d6-6', 3, scaleDamage('1d6-6', 3));
// console.log('20d20+6', 3, scaleDamage('20d20+6', 3));
