import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

const cellHeight = 6;

interface Props {
  fontSize: number | string;
  m: number;
}

export function CardDivider(props: PropsWithChildren<Props>) {
  return (
    <Box className="card-divider" sx={{
      my: props.m,
      height: '0px',
      borderTop: '1px dashed #888',
      textAlign: 'center',
    }}>
      <Box sx={{
        display: 'flex',
      }}>
        <Box sx={{
          // inset shadow
          margin: '0 auto',
          background: 'white',
          lineHeight: `${cellHeight}px`,
          position: 'relative',
          px: '0.5em',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          borderRadius: '6px',
          fontSize: props.fontSize,
          top: `-${cellHeight / 2}px`,
        }}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
