import Dialog from "@mui/material/Dialog";
import {
  AdversaryDetailsFragment,
  AdversaryInput,
  useDeleteAdversaryMutation,
  useUpdateAdversaryMutation
} from "../../generated/graphql";
import { HbAdversaryForm } from "./HbAdversaryForm";
import React from "react";
import ErrorMessage from "../../common/ErrorMessage";
import { defaultAdversaryFormData } from "./config/defaultAdversaryFormData";
import { Box, Paper } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ModalInner } from "../../common/ModalInner";

interface Props {
  open: boolean;
  adversary: AdversaryDetailsFragment;
  handleClose: () => void;
}

export function HbAdversaryEditDialog(props: Props) {
  // Will close on escape key, but not when clicking outside the dialog
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <ModalInner>
        <Box onClick={(e) => e.stopPropagation()} sx={{
          height: '100%',
        }}>
          <AdversaryEditController
            adversary={props.adversary}
            onClose={props.handleClose}
          />
        </Box>
      </ModalInner>
    </Modal>
  );
}

function AdversaryEditController(props: {
  adversary: AdversaryDetailsFragment,
  onClose: () => void,
}) {
  const { adversary } = props;

  const [call, { error, loading }] = useUpdateAdversaryMutation({
    refetchQueries: [
      'ListAdversaries',
      'ListMyAdversaries',
    ],
  });

  const [callDelete, { error: deleteError, loading: deleteLoading }] = useDeleteAdversaryMutation({
    refetchQueries: [
      'ListAdversaries',
      'ListMyAdversaries',
    ],
  });

  return (
    <>
      <ErrorMessage error={error || deleteError} />
      <HbAdversaryForm
        loading={loading || deleteLoading}
        onClose={props.onClose}
        onSubmit={(adversaryInput) => {
          call({
            variables: {
              id: adversary.id,
              input: adversaryInput,
            }
          }).then(() => {
            props.onClose();
          });
        }}
        onDelete={() => {
          callDelete({
            variables: {
              id: adversary.id,
            }
          }).then(() => {
            props.onClose();
          });
        }}
        defaultData={convertAdversaryToFormData(adversary)}
      />
    </>
  );
}

function convertAdversaryToFormData(adversary: AdversaryDetailsFragment): AdversaryInput {
  return {
    name: adversary.name,
    public: !!adversary.public,
    tags: adversary.tags,
    locations: adversary.locations,
    image: adversary.image,
    imageCredit: adversary.imageCredit,
    shortDescription: adversary.shortDescription,
    motivesAndTactics: adversary.motivesAndTactics,
    tier: adversary.tier,
    type: adversary.type,
    hordeUnitsPerHp: adversary.hordeUnitsPerHp,
    difficulty: adversary.difficulty,
    attackModifier: adversary.attackModifier,
    weapon: {
      name: adversary.weapon.name,
      range: adversary.weapon.range,
      damage: adversary.weapon.damage,
    },
    damageThresholds: {
      minor: adversary.damageThresholds?.minor || 1,
      major: adversary.damageThresholds?.major,
      severe: adversary.damageThresholds?.severe,
    },
    hitPoints: adversary.hitPoints,
    stress: adversary.stress,
    experience: adversary.experience,
    features: adversary.features.map((feature) => ({
      name: feature.name,
      type: feature.type,
      description: feature.description,
      flavourText: feature.flavourText,
      value: feature.value,
      attackModifier: feature.attackModifier,
      uses: feature.uses,
      cost: {
        actions: feature.cost?.actions,
        fear: feature.cost?.fear,
        stress: feature.cost?.stress,
        hitPoints: feature.cost?.hitPoints,
      },
      summon: [],
    })),
  };
}


