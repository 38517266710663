import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export function TwoColumn({ children }: PropsWithChildren) {
  return (
    <Stack direction="row" spacing={1} sx={{
      '& > *': {
        width: '50%',
        flexGrow: 1,
      },
    }}>
      {children}
    </Stack>
  );
}
