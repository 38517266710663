import { useLocalStorage } from 'usehooks-ts';
import { DeckCard } from "../encounter/DeckBuilder/Deck.types";

interface Encounter {
  id: string;
  name: string;
  deck: DeckCard[];
}

interface EncountersState {
  currentEncounterId: string | null;
  encounters: Encounter[];
}

const defaultEncounter: Encounter = {
  id: '1',
  name: 'My Encounter',
  deck: [],
};

export function useEncounters() {
  const [encountersState, setEncountersState] = useLocalStorage<EncountersState>('encounters', {
    currentEncounterId: '1',
    encounters: [defaultEncounter],
  });

  const setCurrentEncounterId = (id: string) => {
    setEncountersState(prev => ({ ...prev, currentEncounterId: id }));
  };

  const addEncounter = (name: string) => {
    const id = Date.now().toString();
    const newEncounter: Encounter = {
      id: id,
      name,
      deck: [],
    };
    setEncountersState(prev => ({
      ...prev,
      encounters: [...prev.encounters, newEncounter],
    }));

    return id;
  };

  const renameEncounter = (id: string, newName: string) => {
    setEncountersState(prev => ({
      ...prev,
      encounters: prev.encounters.map(encounter => encounter.id === id ? { ...encounter, name: newName } : encounter),
    }));
  };

  const deleteEncounter = (id: string) => {
    setEncountersState(prev => {
      const updatedEncounters = prev.encounters.filter(encounter => encounter.id !== id);
      return {
        currentEncounterId: prev.currentEncounterId === id ? null : prev.currentEncounterId,
        encounters: updatedEncounters.length > 0 ? updatedEncounters : [defaultEncounter],
      };
    });
  };

  const updateCurrentDeck = (deck: DeckCard[]) => {
    setEncountersState(prev => ({
      ...prev,
      encounters: prev.encounters.map(encounter => encounter.id === prev.currentEncounterId ? { ...encounter, deck } : encounter),
    }));
  }

  return {
    encounters: encountersState.encounters,
    currentEncounterId: encountersState.currentEncounterId,
    currentEncounter: encountersState.encounters.find(encounter => encounter.id === encountersState.currentEncounterId),
    updateCurrentDeck,
    setCurrentEncounterId,
    addEncounter,
    renameEncounter,
    deleteEncounter,
  };
}
