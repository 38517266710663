import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Box } from "@mui/material";

let pending = false;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AuthCallbackView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (pending) {
        return;
      }
      pending = true;
      const query = new URLSearchParams(location.search);
      const code = query.get('code');

      if (code) {
        try {
          const response = await axios.get(`${backendUrl}/auth-token`, { params: {code} });
          localStorage.setItem('token', response.data.token);
          navigate('/');
        } catch (error) {
          console.error('Error during authentication', error);
          setError('Error during authentication');
        }
      } else {
        setError('No code provided');
      }

      pending = false;
    };

    fetchToken();
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}>
      {error && (<div>{error}</div>)}
      {!error && (<div>Authenticating...</div>)}


    </Box>
  );
};

export default AuthCallbackView;

