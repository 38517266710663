import { Box } from '@mui/material';

export function AdversaryExperience(props: { experience?: string[] }) {
  const { experience } = props;

  if (!experience || experience.length === 0) {
    return null;
  }

  // if experience is a string then convert it to an array
  // if (typeof experience === 'string') {
  //   experience = (experience as string).split(', ')
  // }

  return (
    <Box sx={{}}>
      <strong>Experience:</strong>
      {experience.map((e) => (
        <div key={e}>
          {e}
        </div>
      ))}
    </Box>
  );
}
