import { useSettings } from "../hooks/useSettings";
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NumberInput } from "../../common/input/NumberInput";
import { Header2 } from "../../common/typography/Header2";

export function SettingsPanel() {
  return (
    <Box sx={{
      m: '0 auto',
      p: 2,
      maxWidth: '100%',
      width: 500,
    }}>
      <Header2 sx={{
        mb: 1,
        textAlign: 'center',
      }}>Settings</Header2>
      <Paper sx={{p: 2}}>
        <Settings />
      </Paper>
    </Box>
  );
}

function Settings() {
  const { settings, updateSettings } = useSettings();
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography id="input-slider" gutterBottom mr={2}>
          Card size
        </Typography>
        <NumberInput
          aria-label="Card size"
          value={settings.cardSize}
          onChange={(val) => {
            updateSettings({ cardSize: val || 5 });
          }}
          min={1}
          max={10} />
      </Box>
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.detailedView} onChange={(e) => {
            updateSettings({ detailedView: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Detailed View"
      />
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.showActionTracker} onChange={(e) => {
            updateSettings({ showActionTracker: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Show action tracker"
      />
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.showFearTracker} onChange={(e) => {
            updateSettings({ showFearTracker: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Show fear tracker"
      />
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.showPlayerDiceRolls} onChange={(e) => {
            updateSettings({ showPlayerDiceRolls: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Show player dice rolls"
      />
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.disableArtwork} onChange={(e) => {
            updateSettings({ disableArtwork: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Disable Artwork"
      />

      {/*
      <FormControlLabel
        sx={{ ml: 0, display: 'flex', justifyContent: 'space-between', mt: 2 }}
        control={
          <Switch checked={settings.useExperimentalFeatures} onChange={(e) => {
            updateSettings({ useExperimentalFeatures: e.target.checked });
          }} />
        }
        labelPlacement="start"
        label="Use Experimental Features"
      />
      */}

      <FormControl fullWidth sx={{mt: 2}}>
        <InputLabel id="settings-background-label">Background</InputLabel>
        <Select
          labelId="settings-background-label"
          value={settings.background}
          label="Background"
          size="small"
          onChange={(e) => {
            updateSettings({ background: e.target.value })
          }}
        >
          <MenuItem value='default'>Dragon!</MenuItem>
          <MenuItem value='solid'>Solid Color</MenuItem>
          <MenuItem value='greenScreen'>Green screen</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
