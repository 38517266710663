import { DiceRollHistory } from "./DiceRollHistory";
import { Box, Paper } from "@mui/material";
import { usePlayerGameId } from "./hooks/usePlayerGameId";
import SelectedDiceList from "../dice/SelectedDiceList";
import DicePicker from "../dice/DicePicker";
import React, { PropsWithChildren, useEffect } from "react";
import { initiateDiceRollListener } from "../dice/hooks/useDiceRollHistory";
import { baseDarkBlue, baseOffWhite, purple } from "../core/muiTheme";
import { EncounterBackground } from "../game-master/encounter/EncounterBackground";
import { usePlayerName } from "./hooks/usePlayerName";
import { PlayerNameForm } from "./PlayerNameForm";
import { AblyStatus } from "./AblyStatus";

export function PlayerView() {
  const { gameId } = usePlayerGameId();

  if (!gameId) {
    return null;
  }

  const [playerName, setPlayerName] = usePlayerName()

  useEffect(() => {
    (document as any).getElementById("viewport-meta")
      .setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=no')
  })

  // initiateMemberList(gameId);
  initiateDiceRollListener(gameId);

  return (
    <Box sx={{
      // background: baseDarkBlue,
      height: "100%",
    }}>
      <EncounterBackground
        background={baseDarkBlue}
        opacity={0.1}
      />
      {playerName ? (
        <InGameView/>
      ) : (
        <PlayerFormWrapper>
          <PlayerNameForm onSubmit={setPlayerName}/>
        </PlayerFormWrapper>
      )}
    </Box>
  );
}

function InGameView() {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }}>
      <Box sx={{
        position: "relative",
        overflowY: "auto",
        flexGrow: 1,
        flexBasis: 0,
        display: "flex",
        flexDirection: "column",
      }}>
        <AblyStatus />
        <Box flexGrow={1}></Box>
        <Box sx={{
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
        }}>
          <DiceRollHistory/>
        </Box>
      </Box>
      <Box sx={{
        background: purple[300],
      }}>
        <Box sx={{
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
        }}>
          <Box p={1}>
            <SelectedDiceList/>
          </Box>
          <DottedDivider/>
          <Box sx={{ p: 1 }}>
            <DicePicker/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}


function DottedDivider() {
  return (
    <Box sx={{
      height: 0,
      borderTop: '3px dotted #ccc',
      borderColor: purple[100],
      mx: 1,
      my: 0.5,
    }}>
    </Box>
  );
}

function PlayerFormWrapper(props: PropsWithChildren) {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}>
      <Paper sx={{
        p: 2,
        border: "4px solid",
        borderColor: purple[200],
        background: baseOffWhite,
      }} variant="outlined">
      {props.children}
      </Paper>
    </Box>
  );
}

