import React, { PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/material';
import { adversaryImage, useAdversaries } from '../../../adversaries/useAdversaries';
import { TierBadge } from '../../../adversaries/Adversary/TeirBadge';
import { DeckCard } from './Deck.types';
import { AdversaryTypeSimple } from '../../../adversaries/Adversary/AdversaryType';
import { CardEditDialog } from '../CardCustomization/CardEditDialog';
import { DeleteButton, EditButton } from "../../../common/RoundButton";
import { adversaryMainName } from "../../../adversaries/Adversary/AdversaryName";
import { useSettings } from "../../hooks/useSettings";
import { basePurple } from "../../../core/muiTheme";

interface DeckCardItemProps {
  onDeleteClick: () => void,
  card: DeckCard,
  isDragging: boolean,
}

export function DeckCardItem(props: DeckCardItemProps) {
  const {
    card,
    onDeleteClick,
    isDragging,
  } = props;

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const { findAdversary } = useAdversaries();
  const {settings} = useSettings();

  const adversary = findAdversary(card);

  let background: string;
  if (settings.disableArtwork) {
    background = basePurple;
  } else {
    const image = adversaryImage(adversary);
    background = `url(${image}) no-repeat 100% 10% / 70% auto, #333`;
  }




  const onEditClick = () => {
    setOpenEditDialog(true);
  };

  return (
    <Box sx={{
      transform: 'translateZ(0)',
      border: '2px solid #333',
      borderRadius: '5px',
      transition: 'box-shadow 0.2s',
      background: background,
      boxShadow: isDragging ? '0 0 20px rgba(0,0,0,.2)' : 'none',
      fontSize: '10px',
    }}>
      <Box sx={{
        background: 'linear-gradient(90deg, rgba(40,40,40,1) 30%, rgba(40,40,40,0) 100%)',
      }}>
        <Stack spacing={0.5} direction="row" alignItems="center" px={0.25} py={0.5}>
          <Stack>
            <Box sx={{
              ml: '-1.5em',
              mr: '0.5em',
              '& > *': {
                outline: '2px solid #333',
              },
            }}>
              <TierBadge tier={(adversary?.tier || 0) + card.upscaling} borderColor="#333"/>
            </Box>
          </Stack>
          <Stack sx={{
            flexGrow: 1,
            minWidth: 0, // Required for text overflow
          }}>
            <DeckCardName>
              {adversaryMainName(card.name, card.instances)}
            </DeckCardName>
            <Box sx={{
              my: '0.25em',
              fontSize: '1em',
              display: 'flex',
              alignItems: 'center',
            }}>
              <AdversaryTypeSimple type={adversary?.type}/>
              <DeckCardName>
                <Box sx={{ marginLeft: '0.3em' }}>
                  x{card.quantity}
                </Box>
              </DeckCardName>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1} px={1} sx={{
            '& > *': {
              boxShadow: '0 0 8px 1px rgba(0,0,0,.6)',
            },
          }}>
            <EditButton onClick={onEditClick} size="2em"/>
            <DeleteButton onClick={onDeleteClick} size="2em"/>
          </Stack>
        </Stack>
      </Box>
      {(openEditDialog && adversary) && (
        <CardEditDialog
          open={true}
          onClose={() => setOpenEditDialog(false)}
          card={card}
          adversary={adversary}
        />
      )}
    </Box>
  );
}

function DeckCardName(props: PropsWithChildren) {
  return (
    <Box sx={{
      fontWeight: '900',
      color: 'white',
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
    }}>
        {props.children}
    </Box>
  );
}
