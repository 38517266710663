import React from 'react';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box } from '@mui/material';
import {
  baseGrey,
  blue, grey,
} from '../../core/muiTheme';

interface InstanceDamageStressProps {
  max: number;
  value: number;
  onUpdate: (value: number) => void;
  disabled?: boolean;
  color: string;
}

export function BoxBar(props: InstanceDamageStressProps) {
  const {
    max, value, onUpdate, color,
  } = props;
  const adjustValue = (delta: number) => {
    const newValue = Math.max(0, Math.min(max, value + delta)); // Ensure within bounds
    onUpdate(newValue);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <StyledButton onClick={() => adjustValue(-1)}
        disabled={value <= 0}
      >
        <RemoveIcon fontSize="inherit"/>
      </StyledButton>
      <Box sx={{ position: 'relative', flexGrow: 1 }}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          marginTop: '-1px',
          borderTop: '2px solid',
          borderColor: grey[800],
          zIndex: 0,
        }} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
          position: 'relative',
          zIndex: 1,
          px: '1em',
          fontSize: max > 9 ? '0.75em' : '1em',
        }}>
          {Array.from({ length: max }, (_, i) => (
            <Box
              key={i}
              sx={{
                boxSizing: 'border-box',
                width: '1.2em',
                height: '1.2em',
                margin: '0 0.05em',
                borderRadius: '2px',
                border: '1px solid',
                borderColor: grey[800],
                backgroundColor: i < value ? color : baseGrey,
                '&:nth-of-type(3n)': {
                  marginRight: '0.75em',
                },
              }}
            />
          ))}
        </Box>
      </Box>
      <StyledButton onClick={() => adjustValue(1)}
        disabled={value >= max}
      >
        <AddIcon fontSize="inherit"/>
      </StyledButton>
      {/* Similar setup for stressTaken with different color maybe */}
    </Box>
  );
}

const StyledButton = styled('button')(
  ({ theme }) => `
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[800]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : baseGrey};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  width: 2em;
  height: 2em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover:not(:disabled) {
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[800]};
    color: ${grey[50]};
  }
  
  &:disabled {
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);
