import { Control, Controller } from "react-hook-form";
import { Box, MenuItem, Switch, TextField } from "@mui/material";
import { TierBadge } from "../../adversaries/Adversary/TeirBadge";
import React from "react";
import { AdversaryFormData } from "./HbAdversaryFormData.types";

interface BaseInputProps {
  name: string,
  control: Control<AdversaryFormData> | any,
  label: string,
}

interface FormInputProps extends BaseInputProps {
  helperText?: string;
  multiline?: boolean;
  minRows?: number;
  required?: boolean;
}


export const FormInputText = (props: FormInputProps) => {
  const { name, control, label, helperText } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: props.required ? `${label} is required` : undefined
      }}
      render={({ field, fieldState: { error }, }) => (
        <TextField
          {...field}
          helperText={error ? error.message : helperText}
          size="small"
          error={!!error}
          fullWidth
          label={label}
          variant="outlined"
          multiline={props.multiline}
          minRows={props.minRows}
        />
      )}
    />
  );
};

interface FormInputNumberProps extends BaseInputProps {
  helperText?: string;
  valueAsNumber?: boolean;
}

export const FormInputNumber = (props: FormInputNumberProps) => {
  const { name, control, label, helperText } = props;
  // const { register, formState } = useFormContext() // retrieve all hook methods
  // const { name, control, label, helperText } = props;
  // const error = formState.errors[name]?.message as string;
  // return (
  //   <TextField
  //     helperText={error ? error : helperText}
  //     name={name}
  //     inputProps={register(name, { valueAsNumber: true })}
  //     size="small"
  //     error={!!error}
  //     fullWidth
  //     label={label}
  //     variant="outlined"
  //     type="number"
  //   />
  // );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, }) => (
        <TextField
          {...field}
          helperText={error ? error.message : helperText}
          size="small"
          error={!!error}
          fullWidth
          label={label}
          variant="outlined"
          type="number"
          onChange={(e) => {
            if (props.valueAsNumber) {
              field.onChange(parseInt(e.target.value))
            } else {
              field.onChange(e.target.value)
            }
          }}
        />
      )}
    />
  );
};

interface FormInputMultiSelectProps extends BaseInputProps {
  helperText?: string;
  options: string[];
  multiple?: boolean;
}


export const FormInputSelect = (props: FormInputMultiSelectProps) => {
  const { name, control, label, helperText, options, multiple } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          select
          fullWidth
          size="small"
          label={label}
          SelectProps={{ multiple }}
          variant="outlined"
          {...field}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

/**
 * Switch input with custom styling
 */
export function FormInputSwitch(props: BaseInputProps) {
  const { name, control, label } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box>{label}</Box>
          <Switch
            {...field}
            checked={!!field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          />
        </Box>
      )}
    />
  );

}

/**
 * Radio buttons with custom styling
 */
export function FormInputTier(props: { control: Control<AdversaryFormData> }) {
  const tiers = [1, 2, 3, 4];

  return (
    <Controller
      name="tier"
      control={props.control}
      render={({ field }) => (
        <Box sx={{ display: 'flex', gap: 0.5, fontSize: '14px' }}>
          {tiers.map((tier) => (
            <Box
              key={tier}
              sx={{
                opacity: (field.value as any) === tier ? 1 : 0.5,
                cursor: 'pointer',
              }}
              onClick={() => field.onChange(tier)}
            >
              <TierBadge
                key={tier}
                tier={tier}
              />
            </Box>
          ))}
        </Box>
      )}
    />
  );
}
