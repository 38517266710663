import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

export function InfoText() {
  return (
    <Box textAlign="center">
      <Typography mb={1}>
        This project is a "Work in Progress"!
      </Typography>
      <Typography mb={1}>
        All adversaries and their texts are Copyright © Darrington Press, LLC.
        from the current beta test material for Daggerheart 1.5. <br/>
        It's is not affiliated with Darrington Press, LLC.
        and to be used for personal use only.
      </Typography>
      <Typography mb={3}>
        This tool is a supplement to the Daggerheart 1.5 Open Playtest Manuscript
        (which can be downloaded <a href="https://www.daggerheart.com/">here</a>)
        and assumes that the user is
        familiar with the rules and terminology of the game.
        It will not teach you how to play the game.
        For instructions on how to play the game and tips for balancing an encounter,
        please refer to the Daggerheart 1.5 Open Playtest Manuscript.
      </Typography>
      <Typography>
        <DiscordButton/>
      </Typography>
    </Box>
  );
}

export function DiscordButton() {
  return (
    <Button
      href="https://discord.gg/uJtd9ahU92"
      variant="outlined"
      target="_blank"
      sx={{ color: 'black', borderColor: 'black' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Join us on Discord to help improve this tool!
    </Button>
  );
}


export function DiscordButton2() {
  return (
    <Button
      href="https://discord.gg/4y7Nsk5tAz"
      variant="contained"
      color="warning"
      target="_blank"
      sx={{ color: 'black', backgroundColor: 'white' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Join us on Discord to help improve this tool!
    </Button>
  );
}
