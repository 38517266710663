import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import { baseOffWhite, yellow } from "../../../core/muiTheme";
import React, { useState } from "react";
import { GmMainMenuTabs } from "./GmMainMenuTabs";
import { Box } from "@mui/material";

export function GmMainMenuDialogButton() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<MenuIcon/>}>
        Manage
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl" PaperProps={{
        sx: {
          // background: 'none',
          // boxShadow: 'none',
          // borderRadius: 0,
          borderRadius: 0,
          borderWidth: 2,
          borderColor: 'warning.main',
          borderStyle: 'solid',
          backgroundColor: baseOffWhite,
          height: "calc(100% - 64px)",
          // overflowY: "hidden",
          overflow: "visible",
        },
      }
      }>
        <DialogCloseButton close={handleClose}/>
        <GmMainMenuTabs close={handleClose}/>
      </Dialog>
    </div>
  );
}

function DialogCloseButton(props: { close: () => void }) {
  return (
    <Box sx={{
      position: "absolute",
      bottom: '100%',
      right: '-2px',
      zIndex: 1,
      background: `linear-gradient(0deg, ${yellow[500]} 0%, ${yellow[300]} 80%, ${yellow[200]} 100%)`,
      pl: '2em',
      pr: '0.5em',
      clipPath: 'polygon(2em 0%, 100% 0%, 100% 100%, 0% 100%)',
    }}>
      <Button
        onClick={props.close}
        sx={{
          color: '#000',
          borderRadius: 0,
          lineHeight: '0.8em',
        }}
      >Close</Button>
    </Box>
  );
}
