import { BehaviorSubject } from "rxjs";
import { ablyClient, ablyStateSubject } from "../../core/ablyClient";
import { useBehaviorSubject } from "../../common/hooks/useSubject";
import { DiceRollEventPayload } from "../../generated/graphql";
import { InboundMessage, RealtimeChannel } from "ably";

type Message = Omit<InboundMessage, 'data'> & {
  data: DiceRollEventPayload;
};

export const diceRollHistorySubject = new BehaviorSubject<Message[]>([]);

let initiated = false;

export async function initiateDiceRollListener(gameId: string) {
  if (initiated) {
    return;
  }
  initiated = true;

  const channel = ablyClient.channels.get('GAME_' + gameId);

  const updateHistory = (channel: RealtimeChannel) => {
    channel.history({
      limit: 10,
    }).then((messagePage) => {
      const messages = messagePage.items as Message[];
      diceRollHistorySubject.next(messages.reverse());
    });
  }

  // Fetch the latest messages
  updateHistory(channel);

  // Subscribe to presence changes
  channel.subscribe('dice-roll', (message) => {
    diceRollHistorySubject.next([
      ...diceRollHistorySubject.getValue(),
      message as Message
    ]);
  }).then((channelStateChange) => {
    console.info('Channel state changed to ' + channelStateChange?.current);
  });

  // If suspended and then reconnected, fetch the latest messages
  // https://ably.com/docs/connect/states
  ablyClient.connection.on((stateChange) => {
    if (stateChange.current === 'connected') {
      updateHistory(channel);
    }
  });
}

export function useDiceRollHistory() {
  return useBehaviorSubject(diceRollHistorySubject);
}
