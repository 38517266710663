import React, { useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./jwt";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TokenRefresher = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }


    const decodedToken = jwtDecode<JwtPayload>(token);
    console.info('Token found in localStorage', decodedToken);
    const expirationTime = decodedToken.exp! * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const timeoutDuration = expirationTime - currentTime - (5 * 60 * 1000); // 5 minutes before expiry

    if (timeoutDuration <= 0) {
      console.warn('Token already expired or about to expire very soon');
      refreshAuthToken();
    } else {
      const timeoutId = setTimeout(() => {
        refreshAuthToken();
      }, timeoutDuration);

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const refreshAuthToken = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found in localStorage');
      }

      const decodedToken = jwtDecode<JwtPayload>(token);
      const response = await axios.post(`${backendUrl}/auth-token`, {
        refresh_token: decodedToken.refreshToken!,
      });

      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
    } catch (error) {
      console.error('Failed to refresh auth token', error);
    }
  };

  return null;
};

export default TokenRefresher;
