import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function AdversaryCardTop(props: PropsWithChildren) {
  return (
    <Box sx={{
      position: 'relative',
    }}>
      {props.children}
    </Box>
  );
}
