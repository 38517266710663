
let count = 1;
const idMap = new WeakMap();

/**
 * Get the object id for a given object. If the object does not have an id, one is generated.
 * This is needed for react when moving elements in a list.
 * @param object
 */
export function getObjectId(object: any) {
  const objectId = idMap.get(object);
  if (objectId === undefined) {
    count += 1;
    idMap.set(object, count);

    return count;
  }

  return objectId;
}
