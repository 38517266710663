import { useEncounters } from "../hooks/useEncounters";
import React, { useState } from "react";
import { isOpenSubject } from "../top-bar/menu-items/EncounterBuilderButton";
import {
  Alert,
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem, Paper
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import { Header2 } from "../../common/typography/Header2";

interface Props {
  close: () => void;
}

export function EncountersCatalog(props: Props) {
  return (
    <Box sx={{
      m: '0 auto',
      p: 2,
      maxWidth: '100%',
      width: 500,
    }}>
      <Header2 sx={{
        mb: 1,
        textAlign: 'center',
      }}>Encounters</Header2>
      <Paper>
        <EncountersCatalogInner close={props.close} />
      </Paper>
      <Alert severity="info" variant="outlined" sx={{ mt: 2 }}>
        Encounters are stored in the browser.
        In the future they will be linked to your account.
      </Alert>
    </Box>
  )
}

function EncountersCatalogInner(props: Props) {
  const {
    encounters,
    currentEncounterId,
    setCurrentEncounterId,
    addEncounter,
    deleteEncounter
  } = useEncounters();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEncounterId, setSelectedEncounterId] = useState<string | null>(null);

  const handleEditOpen = (id: string) => {
    setCurrentEncounterId(id);
    isOpenSubject.next(true);
    props.close();
  };

  const handleDeleteOpen = (id: string) => {
    setSelectedEncounterId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleAddEncounter = () => {
    const id = addEncounter('New Encounter');
    setCurrentEncounterId(id);
    isOpenSubject.next(true);
    props.close();
  };

  const handleDeleteEncounter = () => {
    if (selectedEncounterId) {
      deleteEncounter(selectedEncounterId);
      setDeleteDialogOpen(false);
    }
  };


  return (
    <div>
      {encounters.map(encounter => (
        <ListItem
          key={encounter.id}
          disablePadding
          secondaryAction={(
            <Box display="flex">
              <IconButton
                edge="start"
                size="small"
                onClick={() => handleEditOpen(encounter.id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDeleteOpen(encounter.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        >
          <ListItemButton
            selected={encounter.id === currentEncounterId}
            onClick={() => setCurrentEncounterId(encounter.id)}
          >
            {encounter.name}
          </ListItemButton>
        </ListItem>
      ))}
      <Divider />
      <MenuItem onClick={handleAddEncounter}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>New Encounter</ListItemText>
      </MenuItem>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this encounter?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={handleDeleteEncounter}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
