import React from "react";
import { Box } from "@mui/material";
import { useListPrivateAdversariesQuery } from "../generated/graphql";
import { AdminAdversaryCatalogGrid } from "./AdminAdversaryCatalogGrid";

export function PrivateAdversariesPage() {
  return (
    <Box m={1} sx={{
      overflowY: 'auto',
    }}>
      <PrivateAdversariesList />
    </Box>
  );
}


function PrivateAdversariesList() {
  const { data, error } = useListPrivateAdversariesQuery();

  const adversaries = data?.listPrivateAdversaries || [];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <AdminAdversaryCatalogGrid adversaries={adversaries} />
  )
}
