import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Box } from '@mui/material';
import { useDeck } from './useDeck';
import { DeckCardItem } from './DeckCardItem';

export function DeckList() {
  const { deck, reorderDeck, removeCardFromDeck } = useDeck();

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // dropped outside the list
    }
    reorderDeck(result.source.index, result.destination.index);
  };

  if (deck.length === 0) {
    return <Box sx={{
      textAlign: 'center',
      fontStyle: 'italic',
    }}>Deck is empty</Box>;
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                width: '100%'
              }}
            >
              {deck.map((card, index) => (
                <Draggable key={card.id.toString()} draggableId={card.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      key={card.id.toString()}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: '0',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Box pb={0.5}>
                        <DeckCardItem
                          card={card}
                          onDeleteClick={() => removeCardFromDeck(card.id)}
                          isDragging={snapshot.isDragging}
                        />
                      </Box>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
